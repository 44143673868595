.datepicker {
  background: #4a4f5f;
  display: flex;
  justify-content: center;
  border-radius: 5px;
}

.datepicker .react-datepicker__day-name,
.datepicker .react-datepicker__day,
.datepicker .react-datepicker__time-name {
  color: #fff;
  font-size: 12px;
  font-weight: bold;
}

.datepicker .react-datepicker__header {
  background-color: transparent;
}

.datepicker .react-datepicker {
  background-color: transparent;
  border: 0;
}

.datepicker .react-datepicker__day--outside-month {
  color: rgba(255, 255, 255, 0.5);
}

.datepicker .react-datepicker__day:hover,
.datepicker .react-datepicker__month-text:hover,
.datepicker .react-datepicker__quarter-text:hover,
.datepicker .react-datepicker__year-text:hover {
  color: var(--action-link-text-color) !important;
  border-radius: 50%;
}

.datepicker .react-datepicker__current-month {
  color: #fff;
}

.datepicker-popper {
  z-index: 9999 !important;
}

.react-datepicker-popper[data-placement^="bottom"] {
  margin-top: unset !important;
}

.full-menu-calendar-screen {
  height: calc(100vh - 450px) !important;
}