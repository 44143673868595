/*
* Uzman Posta WebMail v1.0.0 (https://recepyildiz.net)
* Copyright 20120 RYCreative
* Licensed under RYCreative License
 */

@import url("./colors.css");

/* disable ios auto zoom*/
@media (max-device-width: 768px) {
  .form-control {
    font-size: 16px;
  }
}

/** legacy support */
a {
  text-decoration: none;
  background-color: transparent;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

.form-group {
  margin-bottom: 1rem;
}

.table > :not(:first-child) {
  border-top-width: 1px;
}

.prefix {
  display: flex !important;
  position: relative;
  left: -10px;
  justify-content: flex-end;
  color: grey;
}

input.has-prefix {
  padding-right: 50px;
  margin-right: -50px;
}

.prefix-container {
  display: flex;
  justify-content: space-between;
}

.circle-info {
  color: gray;
}

/** legacy support **/

.custom-disabled-activator:disabled {
  color: rgb(156, 154, 154) !important;
}

/* custom scrollbar */
.with-scrollbar ::-webkit-scrollbar {
  width: 20px;
}

.with-scrollbar ::-webkit-scrollbar-track {
  background-color: transparent;
}

.with-scrollbar ::-webkit-scrollbar-thumb {
  background-color: #c0c0c0;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.with-scrollbar ::-webkit-scrollbar-thumb:hover {
  background-color: #949494;
}

.ReactModal__Overlay {
  z-index: 9995;
}

.tox-tinymce-aux {
  z-index: 9996;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

body,
html {
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: 14px;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

*,
div,
.pus-compose-editor,
.tox-edit-area__iframe {
  scrollbar-color: #c0c0c0 transparent !important;
  scrollbar-width: thin !important;
}

body {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: var(--primary-text-color);
  background-color: var(--primary-bg-color);
  -webkit-font-smoothing: antialiased;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Ubuntu, Helvetica,
    Arial, sans-serif;
}

a {
  color: var(--href-color);
}

label {
  align-content: center;
  display: flex;
  align-items: center;
}

.dropdown-header {
  padding: 0.5rem 1rem;
}

.dropdown-divider {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  margin: 0.5rem 0;
}

.form-control,
.form-control:focus,
.form-control::placeholder {
  background-color: var(--form-control-bg-color);
  border-color: var(--form-control-border-color);
  color: var(--form-control-text-color);
}

.form-control:disabled,
.form-control[readonly] {
  background-color: var(--form-control-bg-color);
  opacity: 0.5;
}

.border,
.border-top,
.border-bottom,
.border-left,
.border-right {
  border-color: var(--border-color) !important;
}

.alert.alert-dismissible .close:focus,
.custom-switch .custom-control-label:focus,
.dropdown .dropdown-toggle:focus,
.dropleft .dropdown-toggle:focus,
.dropright .dropdown-toggle:focus,
.dropup .dropdown-toggle:focus,
.list-group button.list-group-item:focus,
.modal .modal-content .modal-header .close:active,
.modal .modal-content .modal-header .close:focus,
.modal .modal-content .modal-header .close:hover,
.navbar-components-wrapper
  .navbar
  .navbar-container.navbar-dark
  .navbar-toggler:focus,
.navbar-components-wrapper
  .navbar
  .navbar-container.navbar-light
  .navbar-toggler:focus,
.toast .toast-header .close:active,
.toast .toast-header .close:focus,
a:focus {
  outline: 0;
}

.btn.active,
.btn.focus,
.btn:active,
.btn:focus {
  outline: 0;
  box-shadow: none !important;
}

.btn {
  color: var(--primary-text-color);
  border-color: var(--btn-border-color);
}

.btn-forgot {
  border: none !important;
}

:root {
  --sideSvgColor: #fff;
}

.btn:hover {
  color: var(--primary-text-color);
}

.sideBtn:hover {
  background-color: var(--sidebar-active-link-bg-color);
}

.btn-outline-success {
  color: var(--alert-success-text-color);
  border-color: var(--alert-success-border-color);
}

.btn-outline-success:hover {
  color: var(--primary-bg-color);
}

.btn-outline-secondary {
  color: var(--alert-secondary-text-color);
  border-color: var(--alert-secondary-border-color);
}

.btn-outline-secondary:hover {
  color: var(--primary-bg-color);
}

.btn-outline-danger {
  color: var(--alert-danger-text-color);
  border-color: var(--alert-danger-border-color);
}

.btn-outline-danger:hover {
  color: var(--primary-bg-color);
}

.btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.color-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.btn-primary:disabled,
.btn-primary.disabled {
  background-color: var(--primary-color-disabled);
  border-color: var(--primary-color-disabled);
}

.color-primary:disabled,
.color-primary.disabled {
  background-color: var(--primary-color-disabled);
  border-color: var(--primary-color-disabled);
}

.btn-light {
  color: var(--btn-light-text-color);
  background-color: var(--btn-light-bg-color);
  border-color: var(--btn-light-border-color);
}

.btn-light:hover,
.btn-light.active {
  color: var(--btn-light-hover-text-color);
  background-color: var(--btn-light-hover-bg-color);
  border-color: var(--btn-light-hover-border-color);
}

.btn-primary {
  color: var(--btn-primary-text-color) !important;
}

.color-primary {
  color: var(--btn-primary-text-color) !important;
}

.btn-secondary,
.btn-success,
.btn-danger,
.btn-info,
.btn-dark {
  color: #fff !important;
}

.btn-warning {
  color: #212529 !important;
}

.btn-link {
  color: #007bff !important;
}

.btn-link:hover {
  color: #0056b3 !important;
}

.alert-primary {
  color: var(--alert-primary-text-color) !important;
  background-color: var(--alert-primary-bg-color) !important;
  border-color: var(--alert-primary-border-color) !important;
}

.alert-secondary {
  color: var(--alert-secondary-text-color) !important;
  background-color: var(--alert-secondary-bg-color) !important;
  border-color: var(--alert-secondary-border-color) !important;
}

.alert-success {
  color: var(--alert-success-text-color) !important;
  background-color: var(--alert-success-bg-color) !important;
  border-color: var(--alert-success-border-color) !important;
}

.alert-danger {
  color: var(--alert-danger-text-color) !important;
  background-color: var(--alert-danger-bg-color) !important;
  border-color: var(--alert-danger-border-color) !important;
}

.alert-warning {
  color: var(--alert-warning-text-color) !important;
  background-color: var(--alert-warning-bg-color) !important;
  border-color: var(--alert-warning-border-color) !important;
}

.alert-info {
  color: var(--alert-info-text-color) !important;
  background-color: var(--alert-info-bg-color) !important;
  border-color: var(--alert-info-border-color) !important;
}

.alert-light {
  color: var(--alert-light-text-color) !important;
  background-color: var(--alert-light-bg-color) !important;
  border-color: var(--alert-light-border-color) !important;
}

.alert-dark {
  color: var(--alert-dark-text-color) !important;
  background-color: var(--alert-dark-bg-color) !important;
  border-color: var(--alert-dark-border-color) !important;
}

.button-outlined {
  color: var(--alert-danger-text-color) !important;
  border-color: var(--alert-danger-border-color) !important;
  background-color: transparent !important;
}

.button-outlined:hover {
  background-color: rgba(255, 0, 0, 0.2) !important;
}

input.pretty-checkbox[type="checkbox"] {
  border-style: solid;
  border-width: 1px;
  margin: 0;
  border-color: var(--checkbox-border-color);
  background: var(--white-space-bg-color);
  color: #3dbfce;
  clear: none;
  cursor: pointer;
  display: inline-block;
  line-height: 0;
  height: 16px;
  outline: 0;
  padding: 1px;
  text-align: center;
  border-radius: 3px;
  vertical-align: middle;
  width: 16px;
  min-width: 16px;
  -webkit-appearance: none;
  -webkit-transition: 0.05s border-color ease-in-out;
  transition: 0.05s border-color ease-in-out;
  box-shadow: none;
}

input.pretty-checkbox[type="checkbox"]:checked:before {
  display: inline-block;
  speak: none;
  line-height: 16px;
  content: "✔";
  width: 16px;
  height: 16px;
  margin: -2px;
  font-size: 14px;
  background: #596576;
  border-radius: 2px;
  color: #fff;
}

input[type="radio"] {
  border-style: solid;
  border-width: 1px;
  margin: 0;
  border-color: var(--checkbox-border-color);
  background: var(--white-space-bg-color);
  color: #3dbfce;
  clear: none;
  cursor: pointer;
  display: inline-block;
  line-height: 0;
  height: 16px;
  outline: 0;
  padding: 1px;
  text-align: center;
  border-radius: 24px;
  vertical-align: middle;
  width: 16px;
  min-width: 16px;
  -webkit-appearance: none;
  -webkit-transition: 0.05s border-color ease-in-out;
  transition: 0.05s border-color ease-in-out;
  box-shadow: none;
}

input[type="radio"]:checked:before {
  display: inline-block;
  speak: none;
  line-height: 18px;
  content: "";
  width: 10px;
  height: 10px;
  margin: 1px;
  border-radius: 50%;
  font-size: 12px;
  background: #286ff3;
  color: #33a4a5;
}

select:not([multiple]) {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-position: right 50%;
  background-repeat: no-repeat;
  background-image: url("../img/caret-down.png");
}

body[data-theme="dark"] select:not([multiple]) {
  background-image: url("../img/caret-down-white.png");
}

.bg-success {
  background-color: #43db89 !important;
}

.bg-danger {
  background-color: #f65b5c !important;
}

.bg-warning {
  background-color: #fdac41 !important;
}

.bg-info {
  background-color: #00cfdd !important;
}

.bg-primary {
  background-color: var(--primary-color) !important;
}

.nav-tabs {
  border-color: var(--border-color);
}

.nav-tabs .nav-link {
  color: var(--primary-text-color);
  background-color: var(--nav-tabs-link-bg-color);
}

.nav-tabs .nav-link:hover {
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--primary-text-color);
  background-color: var(--white-space-bg-color);
  border-color: var(--border-color) var(--border-color)
    var(--white-space-bg-color);
}

.progress {
  background-color: var(--progress-bg-color);
}

.custom-switch {
  padding-left: 54px;
}

.custom-switch.custom-control {
  min-height: 24px;
}

.custom-switch .custom-control-label::before {
  width: 48px;
  height: 24px;
  border-radius: 20px;
  top: 0;
  left: -54px;
  background-color: var(--custom-switch-bg-color);
  border: 1px solid var(--custom-switch-border-color);
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: var(--custom-switch-checked-text-color);
  border-color: var(--custom-switch-checked-border-color);
  background-color: var(--custom-switch-checked-bg-color);
}

.custom-control-input:disabled ~ .custom-control-label::before {
  border-color: var(--custom-switch-border-color);
  background-color: var(--custom-switch-bg-color);
  opacity: 0.55;
}

.custom-switch .custom-control-label::after {
  width: 20px;
  height: 20px;
  left: -52px;
  top: 2px;
  border-radius: 50%;
  background: #fff;
  -webkit-box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.05),
    0 3px 1px 0 rgba(0, 0, 0, 0.05), 0 2px 2px 0 rgba(0, 0, 0, 0.1),
    0 3px 3px 0 rgba(0, 0, 0, 0.05) !important;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.05), 0 3px 1px 0 rgba(0, 0, 0, 0.05),
    0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 3px 0 rgba(0, 0, 0, 0.05) !important;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  -webkit-transform: translateX(24px);
  transform: translateX(24px);
}

.custom-switch .custom-control-input:disabled ~ .custom-control-label::after {
  opacity: 0.55;
}

.bullet {
  width: 16px;
  height: 16px;
  display: inline-block;
  border-radius: 50%;
}

.bullet.bullet-sm {
  width: 10px;
  height: 10px;
}

.bullet.bullet-success {
  background-color: #39da8a;
}

.bullet.bullet-danger {
  background-color: #ff5b5c;
}

.bullet.bullet-warning {
  background-color: #fdac41;
}

.bullet.bullet-info {
  background-color: #00cfdd;
}

.bullet.bullet-primary {
  background-color: #5a8dee;
}

/* ---------------- Wrap ------------------ */
.wrap {
  position: relative;
  height: 100%;
  width: 100%;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  display: none;
  float: left;
  min-width: 9.65rem;
  padding: 0.4rem 0;
  margin: 0.5rem 0 0;
  font-size: 0.8rem;
  color: #727e8c;
  text-align: left;
  background-color: #fff;
  border: 1px solid var(--border-color);
  border-radius: 5px;
}

.dropdown-menu {
  box-shadow: -7px 8px 16px 0 rgba(55, 70, 95, 0.2);
}

.dropdown-menu::before {
  border-top: 1px solid var(--border-color);
  border-left: 1px solid var(--border-color);
  content: "";
  position: absolute;
  top: -1px;
  left: 1.2rem;
  width: 0.75rem;
  height: 0.75rem;
  display: block;
  background: #fff;
  -webkit-transform: rotate(45deg) translate(-7px);
  -ms-transform: rotate(45deg) translate(-7px);
  transform: rotate(45deg) translate(-7px);
  z-index: 10;
  box-sizing: border-box;
}

.dropdown-menu.dropdown-menu-right::before {
  right: 0.6rem;
  left: auto;
}

.dropdown-menu .dropdown-item {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.dropdown-menu .dropdown-item {
  display: block;
  width: 100%;
  padding: 8px 15px;
  font-size: 13px;
  clear: both;
  text-align: inherit;
  background-color: #0000;
  border: 0;
}

.dropdown-menu .dropdown-item span {
  margin-right: 4px;
}

.dropdown-menu .dropdown-item i {
  position: absolute;
  left: 10px;
  top: 8px;
  font-size: 21px;
}

.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.label {
  position: relative;
  width: 100%;
  font-size: 13px;
  color: #9295a6;
}

.label a {
  display: inline-block;
  position: absolute;
  right: -5px;
  top: -3px;
  font-size: 18px;
  color: #9295a6;
}

.scrollbar {
  overflow: hidden;
}

.hide {
  display: none !important;
}

/* --------------------------------------------------------
   Section - Mobile Header
----------------------------------------------------------- */
.mobile-header {
  position: fixed;
  width: 100%;
  z-index: 998;
  top: 0;
  left: 0;
  padding: 10px 10px 0;
  height: 60px;
  display: flex;
  background: var(--primary-color);
}

@media (max-width: 992px) {
  .header-placeholder {
    height: 60px;
  }
}

.mobile-header a {
  font-size: 26px;
  padding: 2px 0;
  line-height: 1;
  color: #fff;
}

.mobile-header a.bars {
  width: 40px;
  height: 40px;
  margin-right: 40px;
  line-height: 38px;
  text-align: center;
}

.mobile-header a.bars .fa-bars:before {
  content: "\f0c9";
}

.mobile-header a.bars.active .fa-bars:before {
  content: "\f00d";
}

.mobile-header a.logo {
  width: calc(100% - 160px);
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}

.mobile-header a.logo span {
  display: inline-block;
  margin-left: 4px;
  position: relative;
  top: 2px;
}

.mobile-header a.logo > img {
  position: relative;
  top: -2px;
  height: 41px;
}

.mobile-header a.push {
  width: 40px;
  height: 40px;
  line-height: 38px;
  font-size: 24px;
  text-align: center;
  position: relative;
  top: 2px;
}

.mobile-header a.search {
  width: 40px;
  height: 40px;
  margin-left: 40px;
  line-height: 38px;
  text-align: center;
}

.mobile-header a.search .fa-search:before {
  content: "\f002";
}

.mobile-header a.search.active .fa-search:before {
  content: "\f00d";
}

.mobile-header a.bars:hover,
.mobile-header a.bars:focus,
.mobile-header a.bars:active,
.mobile-header a.search:hover,
.mobile-header a.search:focus,
.mobile-header a.search:active {
  background: #00000040;
  border-radius: 50%;
}

.mobile-header-action {
  display: none;
  position: fixed;
  width: 100%;
  z-index: 1001;
  top: 0;
  left: 0;
  padding: 10px 10px;
  height: 60px;
  background: var(--sidebar-bg-color);
}

.mobile-header-action > div {
  width: 50%;
}

.mobile-header-action > div > span {
  display: inline-block;
  height: 40px;
  padding: 0 10px;
  line-height: 38px;
  color: var(--sidebar-text-color);
  font-weight: 600;
  font-size: 15px;
}

.mobile-header-action > div > .btn {
  display: inline-block;
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 26px;
  padding: 2px 0;
  line-height: 6px;
  color: #fff;
}

.mobile-header-action .btn {
  color: #fff;
  font-size: 26px;
  line-height: 6px;
}

.mobile-header-action .btn svg {
  width: 16px;
  height: 16px;
}

.mobile-header-action .dropdown-toggle::after {
  display: none;
}

.mobileSearch {
  display: none;
  background-color: var(--form-control-bg-color);
  border-bottom: 1px solid var(--border-color);
  height: 48px;
  margin: 60px 0 -60px;
}

.mobileSearch button {
  float: left;
  height: 48px;
  line-height: 1;
  font-size: 20px;
  text-align: center;
}

.mw-mobile-menu {
  position: fixed;
  background: #000c;
  height: calc(100% - 106px);
  width: 100%;
  top: 60px;
  z-index: 20;
  display: none;
}

.mw-mobile-menu * {
  transition: opacity linear 0s !important;
}

.mw-mobile-menu *:hover {
  text-decoration: none;
}

.mw-mobile-menu__inner {
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  height: calc(100% - 106px);
  width: 80%;
  max-width: 340px;
  background-color: var(--white-space-bg-color);
}

.mw-mobile-menu__inner .mw-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mw-mobile-menu__inner .mw-header .mw-login {
  width: 100%;
  padding: 0 0 8px 15px;
}

.mw-header:last-child {
  padding-bottom: 15px;
}

.mw-mobile-menu__inner .mw-header .mw-login .mw-avatar {
  float: left;
}

.mw-mobile-menu__inner .mw-header .mw-login .mw-avatar img {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  border-radius: 1.5rem;
  border: 2px solid #fff;
}

.mw-mobile-menu__inner .mw-header .mw-login .mw-user {
  float: left;
  padding: 1px 0 0;
  width: calc(100% - 50px);
  font-weight: 400;
  line-height: 1.5;
}

.mw-mobile-menu__inner .mw-header .mw-login .mw-user p {
  font-size: 14px;
  margin: 0;
}

.mw-mobile-menu__inner .mw-header .mw-login .mw-user span {
  display: block;
  font-size: 11px;
}

.mw-login {
  display: flex;
  align-items: center;
}

.mw-mobile-menu__close {
  position: absolute;
  right: 37px;
  top: 19px;
  z-index: 99999999;
  cursor: pointer;
  font-size: 24px;
  color: #fff;
}

.mw-mobile-menu__container ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.mw-mobile-menu__container ul li {
  border-bottom: 1px solid var(--border-color);
}

.mw-mobile-menu__container ul li:last-child {
  border-bottom: 0;
}

.mw-mobile-menu .mw-mobile-nav__item {
  max-width: 90%;
  padding: 0;
  margin: 0 auto;
  border-radius: 3px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: flex-start;
  padding-right: 1rem;
  position: relative;
  min-height: 42px;
  font-size: 18px;
  font-weight: 500;
  color: var(--primary-text-color);
}

.mw-mobile-menu .mw-mobile-nav__item > i {
  position: relative;
  top: 1px;
  font-size: 22px;
  margin-right: 4px;
}

.mw-mobile-menu .mw-mobile-nav__item:hover {
  color: var(--href-color);
}

.mw-mobile-menu .category-menu-image {
  height: 45px;
}

.mw-mobile-menu .cat-count {
  font-size: 11px;
  font-weight: 700;
  letter-spacing: -0.5px;
  height: 20px;
  position: absolute;
  right: 9px;
  top: 12px;
  text-align: center;
  background-color: #296df3;
  color: #fff;
  border-radius: 100px;
  padding: 0 5px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.main-menu__sub {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  padding: 10px 0 20px;
  height: 100%;
  width: 100%;
  overflow: auto;
  overflow-x: hidden;
  background-color: #fff;
  -webkit-overflow-scrolling: touch;
  transform: translateZ(0);
}

.mw-mobile-menu__submenu {
  width: 80%;
  max-width: 340px;
  top: 65px;
  display: none;
}

.mw-mobile-menu__submenu.active {
  display: block;
}

.mw-mobile-menu__title-holder {
  font-size: 14px;
  max-width: 90%;
  font-weight: 600;
  margin: 0 auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 5px !important;
  margin-bottom: 12px;
}

.mw-mobile-menu__back-btn {
  padding: 0 21px;
  border: solid 1px #ddd;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 600;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  height: 40px;
  display: flex;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.mw-mobile-menu__pill > a {
  max-width: 90%;
  margin: 0 auto;
  background-color: #eee;
  border-radius: 3px;
  margin-bottom: 10px;
  padding: 5px 4px 5px 20px !important;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: flex-start;
  -ms-flex-align: center;
  align-items: center;
  line-height: 1.4rem !important;
  font-size: 12px;
}

.mw-mobile-menu__inner-top h3 {
  background-color: var(--primary-bg-color);
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  padding: 10px 20px;
  line-height: 1;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 600;
  color: #828d99;
}

.mobile-new-compose {
  position: fixed;
  right: 20px;
  bottom: 80px;
  z-index: 100;
  width: 60px;
  height: 60px;
}

.mobile-new-compose .btn {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  font-size: 30px;
  padding: 7px 16px;
}

.mobile-footer {
  position: fixed;
  width: 100%;
  height: 54px;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: var(--sidebar-bg-color);
}

.mobile-footer a {
  float: left;
  width: 20%;
  padding: 5px 0;
  display: block;
  color: var(--sidebar-text-color);
  line-height: 1;
  text-align: center;
  border-right: 1px solid #ffffff12;
}

.mobile-footer a:last-child {
  border-right: 0;
}

.mobile-footer a i {
  display: block;
  font-size: 32px;
  margin: 0 auto;
}

.mobile-footer a span {
  display: block;
  font-size: 11px;
}

.mobile-footer a.active {
  color: #ffffff4f;
  background-color: var(--mobile-footer-active-link-bg-color);
}

/* --------------------------------------------------------
   Section - Desktop Header
----------------------------------------------------------- */
.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  background-color: var(--white-space-bg-color);
  border-bottom: 1px solid var(--border-color);
  height: 60px;
  display: none;
}

.header.minimum .sidebar-header {
  border-right: 0px;
}

.sidebar-header {
  float: left;
  width: 240px;
  height: 59px;
  background: var(--sidebar-header-bg-color);
  border-right: 1px solid var(--sidebar-header-border-color);
}

.search-header {
  float: left;
  width: 36%;
  padding: 11px 8px;
}

.search-header .mailbox-search {
  display: flex;
  position: relative;
  width: 98%;
}

.search-header .mailbox-search .form-control {
  height: 38px;
  padding-left: 45px;
  padding-right: 60px;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.search-header .mailbox-search i {
  position: absolute;
  left: 10px;
  top: 7px;
  font-size: 24px;
  color: var(--form-control-text-color);
}

.search-popup {
  display: none;
  position: absolute;
  top: 49px;
  left: 8px;
  width: calc(100% - 27px);
  padding: 20px;
  border-radius: 0 0 4px 4px;
  background: var(--form-control-bg-color);
  border: 1px solid var(--border-color);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 2;
}

.search-popup.open {
  display: block;
}

.search-popup.search-popup-mobile {
  top: initial;
  zoom: 90%;
  transform: scale(0.9);
}

.search-container div {
  margin-bottom: 6px;
}

.search-container div > span {
  display: inline-block;
  width: 115px;
}

.search-container div > .form-control {
  display: inline-block;
  width: calc(100% - 124px);
  border-radius: 0;
  border: 0;
  box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.19);
}

.search-container div > .form-control:focus {
  -webkit-transition: all 0s;
  transition: all 0s;
  -webkit-box-shadow: inset 0 -1px 0 0 #4285f4;
  box-shadow: inset 0 -1px 0 0 #4285f4;
}

.search-container div .btn-primary {
  font-weight: 500;
  width: 80px;
  text-align: center;
}

.search-container div .btn-light {
  background: #0000;
  border: 0;
  color: #5f6368;
  font-weight: 500;
}

.search-container div .btn {
  border: none;
}

/* modal app */
.modal-app {
  list-style: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.modal-app li {
  padding: 0;
  float: left;
  width: 33.33%;
}

.modal-app li a {
  display: block;
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 11px;
  padding: 13px 10px 10px;
  border-radius: 6px;
}

.modal-app li a .icon {
  display: block;
  width: 42px;
  height: 42px;
  background: var(--primary-color);
  text-align: center;
  padding: 6px 0;
  margin: 0 auto 2px;
  border-radius: 4px;
}

.modal-app li a .icon i {
  display: inline-block;
  font-size: 32px;
}

.modal-app li a .text {
  display: block;
  color: var(--primary-text-color);
  font-size: 13px;
}

.modal-app li a:hover {
  text-decoration: none;
  background: #f3f3f3;
}

.modal-app li:nth-child(1) a .icon {
  background: var(--primary-color);
}

.modal-app li:nth-child(2) a .icon {
  background: #8d68b9;
}

.modal-app li:nth-child(3) a .icon {
  background: #37b091;
}

.modal-app li:nth-child(4) a .icon {
  background: #cb8f4f;
}

.modal-app li:nth-child(5) a .icon {
  background: #a3584a;
}

.modal-app li:nth-child(6) a .icon {
  background: #c84d85;
}
/* documents */
.documents {
  position: relative;
  background: var(--white-space-bg-color);
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.documents-heading {
  display: flex;
}

.documents-layout {
  top: 50px;
  height: calc(100vh - 110px);
  position: relative;
  display: flex;
  flex-direction: column;
}

.document-list {
  height: calc(100% - 62px);
  flex: 1 1 auto;
}

.search-input,
.search-input:focus {
  border: none;
  border-color: none;
  transition: none;
  box-shadow: none;
  padding: 0 0.75rem;
}

.documents-checkbox {
  display: grid;
  grid-template-columns: minmax(200px, 2fr) repeat(3, 1fr);
  font-size: 14px;
  font-weight: 600;
  color: var(--primary-text-color);
  padding: 20px 15px 20px 20px;
  grid-gap: 15px;
  border-bottom: 1px solid var(--border-color);
  background-color: var(--white-space-bg-color);
}

.documents-content-items {
  display: grid;
  grid-template-columns: minmax(200px, 2fr) minmax(100px, 1fr) repeat(3, 1fr);
  font-size: 14px;
  font-weight: 500;
  color: var(--primary-text-color);
  height: 32.5px;
  padding: 0 15px 0 20px;
  grid-gap: 15px;
  border-bottom: 1px solid var(--border-color);
  background-color: var(--white-space-bg-color);
}

.documents-col-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.documents-delete-button svg {
  opacity: 0.5;
  transition: opacity 0.3s ease;
}

.documents-delete-button:hover svg {
  opacity: 1;
}

.documents-popper .action-btn {
  border: 0;
  background: transparent;
  color: #001636;
  outline: none !important;
  opacity: 0.5;
  transition: opacity 0.3s ease;
}

.action-btn:hover {
  opacity: 1;
}

.documents-popper-dropdown {
  margin: 4px -8px;
  min-width: 9rem;
}

.documents-popper-dropdown .dropdown-item {
  position: relative;
  display: block;
  width: 100%;
  padding: 2px 15px;
  font-size: 13px;
  clear: both;
  text-align: inherit;
  border: 0;
  min-width: 9rem;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--primary-text-color);
}

.documents-popper-dropdown .dropdown-item:focus,
.documents-popper-dropdown .dropdown-item:hover {
  color: var(--primary-text-color);
  text-decoration: none;
  background-color: var(--action-link-hover-bg-color);
  outline: 0;
}

.documents-popper-dropdown .dropdown-item:disabled {
  color: #b2b9c0;
}

.documents-popper-dropdown .dropdown-item svg {
  margin-right: 10px;
}

.mfa-icon {
  filter: var(--icon-filter);
}

.sort-btn {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  background-color: #0000;
  border: none;
  padding: 0;
  font-size: 14px;
  font-weight: 600;
  color: #9295a6;
}

.documents-view-buttons {
  display: flex !important;
  align-items: center;
  margin-right: 10px !important;
}

.grid-view {
  color: #757575;
  background-color: transparent;
  border: 2px solid #e5e5e5;
  border-right: none;
  border-radius: 8px 0 0 8px;
  cursor: pointer;
}

.list-view {
  color: #757575;
  background-color: transparent;
  border: 2px solid #e5e5e5;
  border-left: none;
  border-radius: 0 8px 8px 0;
  cursor: pointer;
}

.grid-view.selected,
.list-view.selected {
  color: #0f6dd4;
  background-color: #f0f8ff;
}

.documents-view-buttons.loading {
  opacity: 0.5;
  pointer-events: none;
}

.documents-divider {
  width: 1px;
  height: 35px;
  background-color: #e5e5e5;
}

.grid-view:hover,
.list-view:hover {
  color: #757575;
  border: 2px solid #c4c3c3;
  opacity: 1;
}
.grid-view:hover {
  border-right: none;
}

.list-view:hover {
  border-left: none;
}

.documents-grid-container {
  padding: 16px;
  width: 300px;
}

.documents-grid-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border: 1px solid #dfe3e7;
  border-radius: 8px;
}

.documents-grid-preview {
  width: 100%;
  height: 100px;
  object-fit: contain;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #dfe3e7;
}

.documents-grid-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.documents-grid-name {
  font-size: 14px;
  color: #202124;
  width: 100%;
  .footer-info {
    display: flex;
    gap: 10px;
    font-size: 11px;
  }

  @media (max-width: 1440px) {
    max-width: 150px;
  }

  @media (max-width: 1024px) {
    max-width: 100px;
  }

  @media (max-width: 768px) {
    max-width: 120px;
  }

  @media (max-width: 425px) {
    max-width: inherit;
  }
}

/* applications */
.applications {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 5px 0;
  width: 210px;
}

.applications .dropdown-content {
  background-color: var(--sidebar-app-bg-color);
  padding: 5px;
  display: flex;
  position: relative;
}
.applications .dropdown-content span {
  position: relative;
  right: 0px;
  top: 5px;
}
.applications .dropdown-content .widget {
  box-sizing: border-box;
  margin: 5px 0;
  padding: 0 5px;
}

.applications a:hover,
.applications .link:hover {
  text-decoration: none;
  background: var(--sidebar-app-link-hover-bg-color);
}

.applications a .icon {
  display: block;
  width: 30px;
  height: 30px;
  color: #fff;
  background: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 10px;
  border-radius: 4px;
}

.applications .icon i,
.applications .icon img {
  font-size: 22px;
  height: 22px;
  width: 21px;
}

.applications .text {
  display: block;
  color: var(--sidebar-app-link-text-color);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: normal !important;
}

.applications a {
  display: block;
  text-align: center;
  width: 52px;
  border-radius: 8px;
  color: #333;
  font-size: 11px;
  text-decoration: none;
  position: relative;
  padding-top: 5px;
  padding-bottom: 5px;
}
.applications button {
  display: block;
  text-align: center;
  width: 52px;
  border-radius: 8px;
  color: #333;
  font-size: 11px;
  text-decoration: none;
  position: relative;
  padding-top: 5px;
  padding-bottom: 5px;
  border: none;
  background-color: var(--white-space-bg-color);
}
.applications button .icon {
  display: block;
  width: 30px;
  height: 30px;
  color: #fff;
  background: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 10px;
  border-radius: 4px;
}
.applications button:hover {
  text-decoration: none;
  background: var(--sidebar-app-link-hover-bg-color);
}

.applications .action-btn {
  background-color: var(--white-space-bg-color);
}

.applications .action-button {
  opacity: 0;
  position: absolute;
  top: -1px !important;
  right: 10px !important;
  border-radius: 50%;
  font-size: 20px;
  font-weight: normal;
  cursor: pointer;
  color: #16181b;
  margin-top: -5px;
  width: 8px;
}
.applications .dropdown-content:hover .action-button {
  opacity: 1;
}

.applications .widget-mail .icon {
  background: var(--primary-color);
}

.applications .widget-contacts .icon {
  background: #8d67ba;
}

.applications .widget-calendar .icon {
  background: #37b091;
}

.applications .widget-archive .icon {
  background: #cb8f4e;
}

.applications .widget-quarantine .icon {
  background: #a3584a;
}

.applications .widget-stats .icon {
  background: #c94d84;
}

.applications .widget-documents .icon {
  background: #df4b4b;
}

.applications .widget-logs .icon {
  background: #2c3644;
}

.applications .widget-bulk .icon {
  background: #35af25;
}

.applications .widget-zimbra .icon {
  background: #8c67b6;
}

.applications .widget-owa .icon {
  background: #8c67b6;
}

.applications .widget-others .icon {
  background: var(--primary-color);
}

/* sidebar-app */
.widgets {
  height: calc(100% - 120px);
  margin-bottom: 120px;
  overflow-x: auto;
}

.widgets ul li {
  padding: 3px 0px;
}

.sidebar-app {
  position: fixed;
  right: 0;
  top: 60px;
  width: 0;
  background: var(--sidebar-app-bg-color);
  height: calc(100vh - 59px);
  padding-top: 11px;
  -webkit-transition-duration: 0.15s;
  transition-duration: 0.15s;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.sidebar-app.open {
  width: 75px;
  border-left: 1px solid var(--border-color);
}

.sidebar-app ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar-app ul button {
  border: 0;
  background: none;
}

.sidebar-app ul button,
.sidebar-app ul li a {
  display: block;
  text-align: center;
  width: 52px;
  height: 52px;
  margin: 0 auto 3px;
  padding: 6px 0;
  border-radius: 8px;
  color: #333;
  font-size: 11px;
}

.sidebar-app ul button .icon,
.sidebar-app ul li a .icon {
  display: block;
  width: 26px;
  height: 26px;
  color: #fff;
  background: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 2px;
  border-radius: 4px;
}

.sidebar-app ul button .icon i,
.sidebar-app ul li a .icon i {
  display: inline-block;
  font-size: 22px;
  height: 22px;
  width: 21px;
}

.sidebar-app ul button .icon img,
.sidebar-app ul li a .icon img {
  display: inline-block;
  font-size: 22px;
  height: 22px;
  width: 21px;
}

.sidebar-app ul button .text,
.sidebar-app ul li a .text {
  display: block;
  color: var(--sidebar-app-link-text-color);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.sidebar-app ul li button:hover,
.sidebar-app ul li a:hover {
  text-decoration: none;
  background: var(--sidebar-app-link-hover-bg-color);
}

.sidebar-app .widget-mail .icon {
  background: var(--primary-color);
}

.sidebar-app .widget-contacts .icon {
  background: #8d67ba;
}

.sidebar-app .widget-calendar .icon {
  background: #37b091;
}

.sidebar-app .widget-archive .icon {
  background: #cb8f4e;
}

.sidebar-app .widget-quarantine .icon {
  background: #a3584a;
}

.sidebar-app .widget-stats .icon {
  background: #c94d84;
}

.sidebar-app .widget-logs .icon {
  background: #2c3644;
}

.sidebar-app .widget-documents .icon {
  background: #df4b4b;
}

.sidebar-app .widget-bulk .icon {
  background: #35af25;
}

.sidebar-app .widget-owa .icon {
  background: #8c67b6;
}
.sidebar-app .widget-zimbra .icon {
  background: #8c67b6;
}
.sidebar-app .widget-custom .icon {
  background: var(--primary-color);
}

.sidebar-app ul li .icon.custom-app-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 3px;
}

.sidebar-app .app-version {
  display: none;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  pointer-events: auto;
  position: absolute;
  left: inherit;
  right: 0;
  bottom: 68px;
  text-align: center;
  -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.sidebar-app .app-version i {
  font-size: 20px;
}

.sidebar-app .app-version span {
  display: block;
  line-height: 1.1;
  font-size: 11px;
}

.sidebar-app .app-hide {
  background-color: var(--sidebar-app-bg-color);
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-background-size: 20px 20px;
  background-size: 20px 20px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  pointer-events: auto;
  position: absolute;
  left: inherit;
  right: -10px;
  bottom: 32px;
  width: calc(56px - 8px - 10px);
  height: 40px;
  -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  padding: 10px 0 10px 10px;
  margin: 8px 0 8px 8px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  -webkit-box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
}

.sidebar-app .app-hide svg {
  display: block;
  fill: #5f6368;
  margin: auto;
  -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  left: 10px;
  position: absolute;
  top: 10px;
  margin-left: -6px;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.sidebar-app .app-hide:hover {
  background: var(--sidebar-app-link-hover-bg-color);
  right: 0;
  width: 40px;
}

.sidebar-app.open .app-hide {
  background-color: #0000;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-background-size: 20px 20px;
  background-size: 20px 20px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  pointer-events: auto;
  position: absolute;
  left: 10px;
  right: inherit;
  bottom: 8px;
  z-index: 2;
  width: 40px;
  height: 40px;
  -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: none;
}

.sidebar-app.open .app-hide svg {
  display: block;
  fill: #5f6368;
  margin: auto;
  -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  left: 10px;
  position: absolute;
  top: 10px;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.sidebar-app.open .app-hide:hover {
  background: var(--sidebar-app-link-hover-bg-color);
}

.sidebar-app.open .app-version {
  display: block;
  width: 100%;
}

.sidebar-menu-link {
  float: left;
  width: 62px;
  height: 59px;
  line-height: 52px;
  text-align: center;
  font-size: 30px;
  color: var(--sidebar-text-color);
  background: var(--sidebar-header-bg-color);
  border: 0;
}

.sidebar-menu-link > img {
  width: 32px;
}

.sidebar-menu-link > svg {
  width: 24px;
}

.sidebar-logo {
  display: block;
  float: left;
  width: calc(100% - 82px);
  height: 59px;
  padding: 10px 0;
  font-size: 22px;
  font-weight: 700;
  background-repeat: no-repeat;
  background-position: left;
  background-size: 86px;
}

.sidebar-logo img {
  height: 40px;
}

.action-header {
  float: right;
  width: calc(100% - 650px);
  text-align: right;
}

.action-header ul.nav {
  justify-content: flex-end;
}

.action-header ul.nav li a.nav-link {
  color: var(--action-link-text-color);
  padding: 6.5px 6px 4px;
  margin: 14px 3px 0;
  border-radius: 4px;
}

.action-header .settings-icon {
  margin-top: 16px;
}

.action-header ul.nav li a.nav-link:hover {
  background: var(--action-link-hover-bg-color);
}

.action-header ul.nav li a.nav-link i {
  font-size: 20px;
}

.action-header ul.nav li a.nav-link.btn-link {
  color: var(--href-color);
  position: relative;
  border-right: 1px solid #485e9029;
  padding: 19px 30px 19px 15px;
}

.action-header ul.nav li a.nav-link.btn-link i {
  font-size: 20px;
  position: absolute;
  top: 20px;
  right: 8px;
}

.action-header ul.nav li.dropdown-user {
  border-left: 1px solid #ffffff2b;
  top: -14px;
}

.action-header ul.nav li a.dropdown-user-link {
  padding: 9.5px 8px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.action-header ul.nav li a.dropdown-user-link .user-nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  float: left;
  margin-right: 0.8rem;
}

.action-header ul.nav li a.dropdown-user-link .user-name {
  display: inline-block;
  margin-left: 0.2rem;
  line-height: 1.2;
}

.action-header ul.nav li a.dropdown-user-link .user-status {
  font-size: smaller;
}

.action-header ul.nav li a.dropdown-user-link img {
  border-radius: 4px;
}

.action-header .lnkHelp .dropdown-header {
  padding: 10px;
  font-size: 14px;
  font-weight: 700;
  border-bottom: 1px solid #e9ecef;
}

.action-header .lnkPush .dropdown-header {
  padding: 10px;
  font-size: 14px;
  font-weight: 700;
}

.action-header .lnkPush .dropdown-item {
  padding: 10px 15px 10px 38px;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.action-header .lnkPush .dropdown-item i {
  position: absolute;
  left: 10px;
  top: 10px;
  font-size: 20px;
}

.action-header .lnkPush .dropdown-item span {
  display: block;
  margin-bottom: 15px;
}

.action-header .lnkPush .dropdown-item small {
  display: block;
  float: right;
}

.action-header .lnkLimit .dropdown-header {
  padding: 10px;
  font-size: 14px;
  font-weight: 700;
  border-bottom: 1px solid #e9ecef;
}

.action-header .lnkLimit .nav-link {
  font-size: 11px;
  margin: 10px 5px !important;
}

.action-header .lnkLimit small {
  font-size: 11px;
  display: block;
  line-height: 1.2;
}

.sendingLimitMain {
  position: relative;
  padding: 15px;
  min-width: 340px;
}

.sendingLimitMain p {
  font-size: 12px;
  line-height: 1.4;
}

.sendingLimitMain .sendingLimit {
  position: relative;
}

.sendingLimitMain .sendingLimit > span {
  display: block;
  font-size: 12px;
  margin-bottom: 5px;
}

.sendingLimitMain .sendingLimit .progress {
  border-radius: 0;
  margin-bottom: 15px;
}

.sendingLimitMain .sendingLimit .progress .progress-bar {
  border-radius: 0;
}

.sendingLimitMain .sendingLimit .sendingLimitStats {
  display: block;
  display: flex;
  font-size: 12px;
}

.sendingLimitMain .sendingLimit .sendingLimitStats div {
  display: flex;
}

.sendingLimitMain .sendingLimit .sendingLimitStats div + div {
  margin-left: 10px;
}

.sendingLimitMain .sendingLimit .sendingLimitStats div span {
  display: inline-block;
  margin-right: 5px;
  width: 16px;
  height: 16px;
  background: #f00;
}

.sendingLimitMain .sendingLimit .sendingLimitStats div b {
  margin-right: 3px;
}

.pane {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 15px 0;
}

.pane .dropdown-item {
  border: 2px solid #e5e5e5;
}

.pane .dropdown-item:nth-child(2) {
  margin: 10px 8px;
}

.pane .dropdown-item:hover,
.pane .dropdown-item.active {
  border: 2px solid var(--primary-color);
}

/* ---------------- Sidebar ------------------ */

.sidebar {
  width: 240px;
  height: calc(100% - 59px);
  transform: translateX(-100%);
  background: var(--sidebar-bg-color);
  border-right: 1px solid var(--sidebar-border-color);
}

/* sidebar-menu */
.sidebar-menu {
  position: relative;
  float: left;
  width: 240px;
}

.sidebar-menu .mail-compose {
  position: relative;
  padding: 21.5px 20px;
  min-height: 82px;
  background: var(--sidebar-bg-color);
  border-bottom: 1px solid var(--sidebar-border-color);
  border-right: 1px solid var(--sidebar-border-color);
}

.sidebar-menu .mail-compose .btn {
  font-weight: 600;
  line-height: 2em;
  min-height: 38px;
}

.sidebar-menu .mail-compose .btn i {
  display: none;
}

.sidebar-main {
  height: calc(100vh - 141px);
  overflow-y: scroll;
}

.sidebar-main .mail-menu {
  position: relative;
  padding-bottom: 1px;
  padding-top: 15px;
  overflow-x: hidden;
  border-right: 1px solid var(--sidebar-border-color);
}

.sidebar-main .mail-menu .label {
  padding: 0 20px;
}

.sidebar-main .mail-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar-main .mail-menu ul li {
  position: relative;
}

.sidebar-main .mail-menu ul li .sideBtn {
  position: absolute;
  right: 10px;
  top: -16px;
  display: inline-block;
  margin-left: 3px;
  margin-top: -1px;
  border-bottom: 3px solid;
  border-right: 3px solid;
  transition: transform 0.3s;
  transform: scale(0.75) rotate(-45deg);
  width: 12px;
  height: 12px;
  cursor: pointer;
  color: #888c9c;
}

.sidebar-main .mail-menu ul li .sideBtn.open {
  transform: scale(0.75) rotate(135deg);
  top: 4px;
}

.sidebar-main .mail-menu ul li .label {
  padding: 0 20px;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 15px;
}

.sidebar-main .mail-menu ul li a {
  position: relative;
  text-transform: capitalize;
  display: block;
  font-size: 13px;
  padding: 6px 20px 7px 46px;
  color: var(--sidebar-text-color);
}

.sidebar-main .mail-menu ul li a i {
  position: absolute;
  left: 20px;
  top: 5px;
  width: 22px;
  font-size: 22px;
}

.sidebar-main .mail-menu ul li a input[type="checkbox"] {
  position: absolute;
  left: 20px;
  top: 6px;
  width: 18px;
  height: 18px;
  border: 0;
}

.sidebar-main .mail-menu ul li a input[type="checkbox"]:checked:before {
  width: 18px;
  height: 18px;
  line-height: 18px;
  margin: -1px -2px;
}

.sidebar-main .mail-menu ul li#folderCreate a {
  font-size: 11px;
  color: #9295a6;
  padding-left: 42px;
}

.sidebar-main .mail-menu ul li#folderCreate a i {
  font-size: 18px;
}

.sidebar-main .mail-menu ul li#folderCreate > span {
  position: relative;
  display: block;
  width: 100%;
  padding: 6px 20px 7px 46px;
  color: #fff;
}

.sidebar-main .mail-menu ul li#folderCreate > span input {
  border: 0;
  border-radius: 3px;
  position: relative;
  top: 0;
  width: 170px;
  height: 22px;
  font-size: 12px;
  padding: 3px 7px;
}

.sidebar-main .mail-menu ul li#folderCreate > span i {
  position: absolute;
  left: 20px;
  top: 5px;
  width: 22px;
  font-size: 22px;
}

.sidebar-main .mail-menu ul li a .badge {
  float: right;
  margin-top: 2px;
  padding: 0.28rem 0.4rem;
  border-radius: 2rem;
  background-color: var(--primary-color);
  color: #fff !important;
}

.sidebar-main .mail-menu ul li a:hover {
  text-decoration: none;
  background: var(--sidebar-link-hover-bg-color);
}

.sidebar-main .mail-menu ul li a.active {
  background: var(--sidebar-active-link-bg-color);
  font-weight: 700;
}

.sidebar-main .mail-menu ul li a.active:after {
  content: "";
  width: 2px;
  height: 32px;
  background-color: var(--primary-color);
  position: absolute;
  top: 0;
  left: 0;
}

.sidebar-main .mail-menu ul li a.sub-folder:before {
  position: absolute;
  content: "";
  left: 11px;
  top: 10px;
  display: inline-block;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transition: transform 0.3s;
  transform: translateY(2px) scale(0.75) rotate(-45deg);
  width: 8px;
  height: 8px;
  color: #c4c4c7;
}

.sidebar-main .mail-menu ul li a.sub-folder.open:before {
  transform: translateY(0) scale(0.75) rotate(45deg);
}

.sidebar-main .mail-menu .tree {
  padding-left: 15px;
  display: none;
}

.minimum .sidebar-main .mail-menu .tree {
  padding-left: 0;
}

.sidebar-main .mail-sticker {
  position: relative;
  padding: 20px;
  background: #1a1b2b;
  height: 100%;
}

.sidebar-main .mail-sticker .label {
  margin-bottom: 10px;
}

.sidebar-main .mail-sticker ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar-main .mail-sticker ul li a {
  position: relative;
  display: block;
  padding: 6px 0;
  margin: 4px 0;
  color: #fff;
}

.sidebar-main .mail-sticker ul li a span {
  position: absolute;
  right: 0;
  top: 10px;
}

.sidebar-main .mail-sticker ul li a:hover {
  text-decoration: none;
}

.sidebar-main .mail-folder {
  position: relative;
  padding: 20px 0 0;
  background: #1a1b2b;
}

.sidebar-main .mail-folder .label {
  margin-bottom: 10px;
  padding: 0 20px;
}

.sidebar-main .mail-folder .label a {
  right: 20px;
}

.sidebar-main .mail-folder > ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar-main .mail-folder > ul > li > a {
  position: relative;
  display: block;
  padding: 6px 0 6px 44px;
  font-size: 14px;
  margin: 4px 0;
  color: #ffffffb5;
}

.sidebar-main .mail-folder > ul > li > a i {
  position: absolute;
  left: 20px;
  top: 7px;
  width: 20px;
  font-size: 20px;
}

.sidebar-main .mail-folder > ul > li > a img {
  position: absolute;
  left: 20px;
  top: 4px;
  width: 24px;
  height: 24px;
}

.sidebar-main .mail-folder > ul > li > a .badge {
  position: absolute;
  right: 0;
  top: 10px;
}

.sidebar-main .mail-folder > ul > li > a:hover {
  text-decoration: none;
  background: var(--sidebar-link-hover-bg-color);
}

.sidebar-main .mail-folder > ul > li > a input {
  border: 0;
  border-radius: 3px;
  position: relative;
  top: 0;
  width: 170px;
  height: 22px;
  font-size: 12px;
  padding: 3px 7px;
}

.sidebar-main .mail-folder > ul > li > a.sub-folder:before {
  position: absolute;
  content: "";
  left: 11px;
  top: 10px;
  display: inline-block;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transition: transform 0.3s;
  transform: scale(0.75) rotate(45deg);
  width: 8px;
  height: 8px;
  color: #c4c4c7;
}

.sidebar-main .mail-folder > ul > li > a.sub-folder.open:before {
  transform: translateY(4px) scale(0.75) rotate(-135deg);
}

.sidebar-main .mail-folder .tree {
  display: none;
}

.sidebar-main .mail-folder > ul > li ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar-main .mail-folder > ul > li ul li a {
  position: relative;
  display: block;
  padding: 6px 0 6px 46px;
  font-size: 14px;
  margin: 4px 0;
  color: #ffffffb5;
}

.sidebar-main .mail-folder > ul > li ul li a i {
  position: absolute;
  left: 32px;
  top: 6px;
  width: 20px;
  font-size: 18px;
}

.sidebar-main .mail-folder > ul > li ul li a img {
  position: absolute;
  left: 20px;
  top: 4px;
  width: 24px;
  height: 24px;
}

.sidebar-main .mail-folder > ul > li ul li a:hover {
  text-decoration: none;
  background: var(--sidebar-link-hover-bg-color);
}

.level-2 a {
  padding-left: 46px !important;
  font-size: 13px !important;
}

.level-2 a img {
  left: 30px !important;
}

.sidebar-progressbar {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 240px;
  padding: 10px 15px 10px;
  background: #1b1c2c;
  border-top: 1px solid #292a3f;
}

.sidebar-progressbar .progress-text {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: #ffffffb5;
  font-size: 11px;
  margin-bottom: 4px;
}

.sidebar-progressbar .progress-text .used {
  justify-content: flex-start;
}

.sidebar-progressbar .progress-text .stats {
  justify-content: flex-end;
}

.sidebar-progressbar .progress {
  background-color: #333449;
  height: 8px;
}

.sidebar-progressbar .upgrade {
  color: #ffffffb5;
  font-size: 10px;
  margin-top: 4px;
}

/* sidebar-fixed */
.sidebar-fixed {
  position: fixed;
  top: 59px;
  left: 0;
  bottom: 0;
  z-index: 1000;
}

.header.minimum .sidebar-header {
  background: var(--white-space-bg-color);
}

.sidebar.minimum {
  width: 62px;
}

.sidebar.minimum .sidebar-menu {
  width: 100%;
}

.sidebar.minimum .sidebar-menu .mail-compose {
  padding: 21.5px 10px;
}

.sidebar.minimum .mail-compose .btn {
  padding: 0;
}

.sidebar.minimum .mail-compose .btn i {
  display: block;
  font-size: 28px;
}

.sidebar.minimum .mail-compose .btn span {
  display: none;
}

.sidebar.minimum .sidebar-main .mail-menu ul li .label {
  display: none;
}

.sidebar.minimum .mail-menu ul li a {
  height: 32px;
  padding-right: 0;
}

.sidebar.minimum .mail-menu ul li a span {
  display: none;
}

.sidebar.minimum .mail-menu ul li a span {
  display: none;
}

.sidebar.minimum .mail-menu ul li#folderCreate button {
  min-height: 30px;
}

.sidebar.minimum .mail-menu ul li#folderCreate span {
  display: none;
}

.sidebar.minimum .sidebar-main {
  height: calc(100vh - 141px);
}

.sidebar.minimum .sidebar-main .mail-folder .label a {
  right: 22px;
  top: -16px;
}

.sidebar.minimum .sidebar-main .mail-folder .label span {
  display: none;
}

.sidebar.minimum .sidebar-main .mail-folder .label {
  display: none;
}

.sidebar.minimum .mail-folder > ul > li > a {
  height: 36px;
}

.sidebar.minimum .mail-folder > ul > li > a span {
  display: none;
}

.sidebar.minimum .sidebar-main .mail-folder > ul > li ul li a {
  height: 36px;
}

.sidebar.minimum .sidebar-main .mail-folder > ul > li ul li a span {
  display: none;
}

.sidebar.minimum .sidebar-main .mail-menu ul li a .badge {
  float: none;
  position: absolute;
  right: 5px;
  top: 0;
}

.sidebar.minimum .sidebar-progressbar {
  display: none !important;
}

/* ---------------- Content ------------------ */
.sidebar-fixed + .mailbox {
  margin-left: 0;
  margin-top: 0;
  height: 100vh;
}

.mailbox {
  float: left;
  width: 100%;
  overflow: hidden;
  height: calc(100vh - 231px);
}

/* mailbox-footer */
.mailbox .mailbox-footer {
  position: fixed;
  bottom: 54px;
  left: 0;
  z-index: 6;
  width: 100%;
  margin-left: 0;
  background: var(--white-space-bg-color);
  padding: 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: none;
  border-top: 1px solid var(--border-color);
}

/* mailbox-title */
.mailbox .mailbox-title {
  position: fixed;
  top: 60px;
  left: 0;
  z-index: 6;
  width: 100%;
  margin-left: 0;
  background: var(--white-space-bg-color);
  padding: 10px 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: none;
  border-bottom: 1px solid var(--border-color);
}

.mailbox .mailbox-title ul.mail-action1 {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-left: 12px;
  display: flex;
  align-items: center;
}

.mailbox .mailbox-title ul.mail-action1 li {
  display: inline-block;
  margin-right: 4px;
}

.mailbox .mailbox-title ul.mail-action1 li .btn-icon {
  padding: 1px 5px;
  border: 1px solid var(--border-color);
  color: var(--icon-btn-text-color);
  line-height: 14px;
  height: 32px;
}

.mailbox .mailbox-title ul.mail-action1 li .btn-icon:hover,
.mailbox .mailbox-title ul.mail-action1 li .btn-icon:focus {
  background: var(--action-link-hover-bg-color);
}

.mailbox .mailbox-title ul.mail-action1 li .btn-icon i {
  display: inline-block;
  font-size: 21px;
}

.mailbox .mailbox-title ul.mail-action1 li .btn-primary {
  height: 32px;
  padding-left: 20px;
  padding-right: 20px;
}

.mailbox .mailbox-title ul.mail-action1 li .no-toggle {
  padding: 0;
  font-size: 21px;
  width: 32px;
}

.mailbox .mailbox-title ul.mail-action1 li .no-toggle:after {
  display: none;
}

.mailbox .mailbox-title ul.mail-action1 li:nth-child(1) {
  margin-right: 10px;
}

.mailbox .mailbox-title ul.mail-action1 li:nth-child(1) button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mailbox .mailbox-title ul.mail-action1 li.heading {
  font-size: 16px;
  font-weight: 600;
  padding: 5px 5px 0;
}

.mailbox .mailbox-title ul.mail-action2 {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 5px;
}

.mailbox .mailbox-title ul.mail-action2 li {
  display: flex;
  margin-left: 5px;
}

.mailbox .mailbox-title ul.mail-action2 li:nth-child(1) {
  font-size: 12px;
  color: #231f2080;
}

.mailbox .mailbox-title ul.mail-action2 li .btn-icon {
  padding: 6px;
  width: 32px;
  border: 1px solid var(--border-color);
  line-height: 14px;
  height: 32px;
  color: var(--icon-btn-text-color);
}

.mailbox .mailbox-title ul.mail-action2 li .btn-icon i {
  display: inline-block;
}

.mailbox .mailbox-title ul.mail-action2 li .btn-primary {
  height: 32px;
  padding-left: 20px;
  padding-right: 20px;
  line-height: 20px;
  white-space: nowrap;
}

.mailbox .mailbox-title ul.mail-action2 li .form-control {
  height: 32px;
}

.mailbox .mailbox-detail {
  margin-top: 0;
  overflow: hidden;
  height: 100vh;
}

@media (max-width: 321px) {
  ul.mail-action2 li .btn-primary {
    white-space: wrap !important;
    margin-left: 5px;
    height: auto !important;
  }
}

/* mail-list */

.mailbox .mailbox-detail .mailbox-list {
  position: relative;
  float: left;
  width: 100%;
  /* overflow-y: auto; */
  height: calc(100% - 114px);
}

@media (max-width: 1024px) {
  .mailbox .mailbox-detail .mailbox-list {
    height: calc(100% - 134px);
  }
}

.mailbox-setting-body {
  background-color: var(--white-space-bg-color);
  height: 100%;
}

.mailbox-list .mail-list {
  list-style: none;
  padding: 0;
  margin: 0;
  height: 100%;
  /* height: calc(100% - 20px); */
  overflow-y: auto;
  scrollbar-color: #c0c0c0 transparent !important;
  scrollbar-width: thin !important;
}

.mailbox-list .mail-paginate {
  padding-bottom: 45px;
}

.mailbox-list .mail-list li .mxItemView,
.task-list li a.mxItemView {
  cursor: pointer;
  -webkit-animation: fadeIn 0.5s linear;
  animation: fadeIn 0.5s linear;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  position: relative;
  min-height: 75px;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  z-index: 1;
  width: 100%;
  padding: 8px 15px 8px 15px !important;
  background-color: var(--mxitem-view-select-bg-color-unread);
}

.mailbox-list .mail-list li .mxItemView:hover,
.task-list li .mxItemView:hover {
  background: var(--mxitem-view-select-bg-color-hover) !important;
}

.mailbox-list .mail-list li .mxItemGroupView {
  box-shadow: 0 2px 3px -2px rgba(0, 0, 0, 0.2) inset,
    0 -2px 3px -2px rgba(0, 0, 0, 0.2) inset;
  display: none;
  list-style: none;
  padding-left: 30px;
}

.mailbox-list .mail-list li .mxItemGroupView .mx-subject {
  margin-left: 20px;
}

.mailbox-list .mail-list li .mxItemGroupView .mxItemView {
  position: relative;
  border-top: 1px solid var(--border-color);
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 40px;
}

.mailbox-list .mail-list li .mxItemGroupView .mxItemView:before {
  position: absolute;
  content: "";
  left: 16px;
  top: 0;
  width: 1px;
  height: 35px;
  background: var(--border-color);
}

.mailbox-list .mail-list li .mxItemGroupView .mxItemView:after {
  position: absolute;
  content: "";
  left: 16px;
  top: 0;
  width: 1px;
  height: 70px;
  background: var(--border-color);
}

.mailbox-list .mail-list li .mxItemGroupView .mxItemView:last-child:after {
  position: absolute;
  content: "";
  left: 16px;
  top: 35px;
  width: 15px;
  height: 1px;
  background: var(--border-color);
}

.mailbox-list .mail-list li.mxItem.expand .mxItemGroupView {
  display: block;
  padding: 0;
  margin: 0;
}

.mailbox-list .mail-list li.mxItem:not(:first-child) .mxItemView {
  border-top: 1px solid var(--border-color);
}

.mailbox-list .mail-list li.mxItem:nth-child(2) .mxItemView {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.mailbox-list .mail-list li.mxItem.active a,
.task-list li.mxItem.active a.mxItemView {
  background: var(--mxitem-view-select-bg-color-hover) !important;
}

.mailbox-list .mail-list li.heading {
  background-color: var(--primary-bg-color);
  border-top: 1px solid var(--border-color);
  padding: 9px 15px;
  line-height: 1;
  font-size: 12px;
  font-weight: 600;
  color: #828d99;
}

.mailbox-list .mail-list li.heading:hover {
  box-shadow: none;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.mailbox-list .mail-list li:first-child.heading {
  border-top: 0;
}

.mailbox-list .mail-list li .mxItemView .mx-action {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 8px;
  margin-top: 5px;
}

.mailbox-list .mail-list li .mxItemView .mx-action:after {
  position: absolute;
  content: "";
  left: 9px;
  bottom: -15px;
  width: 10px;
  height: 10px;
  background: #2196f3;
  border-radius: 50%;
}

/* #848 -> bolmesiz gorunum duzeltmesi: .mx-action:after */
.mailbox-list.default .mail-list li .mxItemView .mx-action:after {
  left: -9px;
  top: 10px;
  bottom: unset;
}

.mailbox-list .mail-list li .mxItemView .mx-action .checkbox {
  display: inline-block;
  margin-right: 8px;
  line-height: 1;
  width: 28px;
}

.mailbox-list
  .mail-list
  li
  .mxItemView
  .mx-action
  .checkbox
  input[type="checkbox"] {
  border-color: var(--mxitem-view-action-checkbox-border-color);
}

.mailbox-list .mail-list li .mxItemView .mx-action .favorite {
  display: inline-block;
  font-size: 20px;
  line-height: 1;
}

.mailbox-list .mail-list li .mxItemView .mx-action .favorite i {
  position: relative;
  color: var(--mxitem-view-action-checkbox-border-color);
}

.mailbox-list .mail-list li .mxItemView .mx-action .favorite.open i {
  position: relative;
  color: #fdac41;
}

.mailbox-list .mail-list li .mxItemView .mx-action .tracking {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 24px;
  top: 34px;
  font-size: 20px;
  color: #dadee2;
}

.mailbox-list .mail-list li .mxItemView .mx-action .tracking i {
  display: block;
  cursor: pointer;
  -webkit-user-drag: none;
}

.mailbox-list .mail-list li .mxItemView .mx-action .tracking i.tick {
  width: 18px;
  height: 18px;
  background: #f00;
}

.mailbox-list .mail-list li .mxItemView .mx-action .tracking svg {
  position: relative;
  z-index: -1;
  top: 3px;
  left: 2px;
  display: block;
  transform: scale(1.2);
}

.mailbox-list .mail-list li .mxItemView .mx-action .tracking svg path {
  fill: #92a58c;
}

.mailbox-list .mail-list li .mxItemView .mx-action .tracking.open {
  color: var(--primary-text-color);
}

.mailbox-list .mail-list li .mxItemView .mx-action .tracking.open svg path {
  fill: #296ef3;
}

.mailbox-list .mail-list li .mxItemView .mx-avatar {
  height: 38px;
  width: 38px;
  background-color: #c3c3c3;
  position: relative;
  cursor: pointer;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 0.8rem;
  text-align: center;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;
}

.mailbox-list .mail-list li .mxItemView .mx-avatar img {
  height: 38px;
  width: 38px;
  border-radius: 50%;
}

.mailbox-list .mail-list li .mxItemView .mx-body {
  overflow: hidden;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

/* #848 -> bolmesiz gorunum duzeltmesi: .mx-body */
.mailbox-list.default .mail-list li .mxItemView .mx-body {
  overflow: visible;
}

.mailbox-list .mail-list li .mxItemView .mx-body .mx-subject {
  text-align: left;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;

  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;

  position: relative;
}

.mailbox-list .mail-list li .mxItemView .mx-body .mx-subject .title {
  font-size: 13px;
  font-weight: 700;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.mailbox-list .mail-list li .mxItemView .mx-body .mx-subject .title > span {
  display: block;
}

.mailbox-list
  .mail-list
  li
  .mxItemView
  .mx-body
  .mx-subject
  .title
  > span
  > img {
  position: relative;
  top: -2px;
  opacity: 0.8;
  display: inline-block;
  margin-right: 10px;
}

.mailbox-list .mail-list li .mxItemView .mx-body .mx-subject .date {
  float: right;
  font-size: 11px;
  opacity: 0.7;
  font-weight: 400;
}

/* #848 -> bolmesiz gorunum duzeltmesi: date */
.mailbox-list.default .mail-list li .mxItemView .mx-body .mx-subject .date {
  margin-top: -10px;
}

/* #848 -> bolmesiz gorunum duzeltmesi: date */
.mailbox-list.default
  .mail-list
  li
  .mxItemGroupView
  .mx-body
  .mx-subject
  .date {
  margin-top: -7px;
}

.mailbox-list .mail-list li .mxItemView .mx-body .mx-message {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;

  /* #848 -> bolmesiz gorunum duzeltmesi: attachment */
  position: absolute;
  right: 0;
  bottom: 0;
}

/* #848 -> bolmesiz gorunum duzeltmesi: attachment */
.mailbox-list.default .mail-list li .mxItemView .mx-body .mx-message {
  bottom: -12px;
}

/* #848 -> bolmesiz gorunum duzeltmesi: attachment */
.mailbox-list.default .mail-list li .mxItemGroupView .mx-body .mx-message {
  bottom: -4px;
}

.mailbox-list .mail-list li .mxItemView .mx-body .mx-message .text-desc {
  font-size: 12px;
  color: #828d99;
  margin-right: 55px;
  line-height: 1.75;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  margin-bottom: 0;
}

.mailbox-list
  .mail-list
  li
  .mxItemView
  .mx-body
  .mx-message
  .text-desc
  .text-attachment {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  height: 26px;
  margin-left: 2px;
  margin-top: 7px;
  -webkit-box-ordinal-group: 1;
  -webkit-order: 1;
  order: 1;
  width: 100%;
}

.mailbox-list
  .mail-list
  li
  .mxItemView
  .mx-body
  .mx-message
  .text-desc
  .text-attachment
  .tx-attach {
  -webkit-align-items: center;
  align-items: center;
  -webkit-border-radius: 15px;
  border-radius: 15px;
  -webkit-box-shadow: inset 0 0 0 1px rgba(100, 121, 143, 0.302);
  box-shadow: inset 0 0 0 1px rgba(100, 121, 143, 0.302);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #5f6368;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  height: 26px;
  padding: 0 12px;
  margin-right: 8px;
  max-width: 160px;
  min-width: 38px;
}

.mailbox-list
  .mail-list
  li
  .mxItemView
  .mx-body
  .mx-message
  .text-desc
  .text-attachment
  .tx-attach:hover {
  background-color: #80868b0a;
}

.mailbox-list
  .mail-list
  li
  .mxItemView
  .mx-body
  .mx-message
  .text-desc
  .text-attachment
  .tx-attach
  img {
  height: 16px;
}

.mailbox-list
  .mail-list
  li
  .mxItemView
  .mx-body
  .mx-message
  .text-desc
  .text-attachment
  .tx-attach
  span {
  line-height: 26px;
}

.mailbox-list .mail-list li .mxItemView .mx-body .mx-message .text-meta {
  /* float: right; */
  /* position: absolute; */
  /* right: 15px; */
  /* top: 57px; */
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mailbox-list .mail-list li .mxItemView .mx-body .mx-message .text-meta i {
  font-size: 16px;
  margin-left: 3px;
}

.mailbox-list
  .mail-list
  li
  .mxItemView
  .mx-body
  .mx-message
  .text-meta
  .bullet {
  margin-left: 3px;
}

.mailbox-list
  .mail-list
  li
  .mxItemView
  .mx-body
  .mx-message
  .text-meta
  .msg-snippet {
  display: inline-block;
  border: 1px solid var(--border-color);
  border-radius: 3px;
  font-size: 10px;
  padding: 3px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mailbox-list
  .mail-list
  li
  .mxItemView
  .mx-body
  .mx-message
  .text-meta
  .msg-snippet
  .snippet-arrow {
  display: inline-block;
  margin-left: 3px;
  margin-top: -1px;
  border-bottom: 1px solid;
  border-right: 1px solid;
  transition: transform 0.3s;
  transform: scale(0.75) rotate(45deg);
  width: 8px;
  height: 8px;
  color: var(--primary-text-color);
  opacity: 0.45;
}

.mailbox-list .mail-list li .mxItemView .mx-trash {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 15px;
  height: 46px;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: flex-end;
  display: none;
  background-color: transparent !important;
}

/* #848 -> bolmesiz gorunum duzeltmesi: row-buttons */
.mailbox-list.default .mail-list li .mxItemView .mx-trash {
  right: 52px;
  top: 2px;
}

/* #848 -> bolmesiz gorunum duzeltmesi: row-buttons */
.mailbox-list.default .mail-list li .mxItemGroupView .mxItemView .mx-trash {
  top: -4px;
}

.btn-primary:hover {
  background-color: var(--btn-primary-bg-color);
  border-color: var(--btn-primary-border-color);
}

.mailbox-list .mail-list li .mxItemView .mx-trash .btn-light {
  border: 1px solid var(--border-color);
  background: var(--white-space-bg-color);
  font-size: 18px;
  margin: 0 1px;
  padding: 1.5px 6px;
}

.mailbox-list .mail-list li .mxItemView .mx-trash .btn-light:hover,
.mailbox-list .mail-list li .mxItemView .mx-trash .btn-light:focus {
  background: var(--action-link-hover-bg-color);
}

.mailbox-list
  .mail-list
  li.mxItem.expand
  .mxItemView
  .mx-body
  .mx-message
  .text-meta
  .msg-snippet
  .snippet-arrow {
  transform: translateY(4px) scale(0.75) rotate(-135deg);
}

.mailbox-list
  .mail-list
  li.mxItem.expand
  .mxItemGroupView
  .mx-body
  .mx-message
  .text-meta {
  top: 43px;
}

.mailbox-list .mail-list li .mxItemView.mail-read {
  background-color: var(--white-space-bg-color);
}

.task-list li a.mxItemView {
  background-color: var(--white-space-bg-color) !important;
  border-top: 1px solid var(--border-color);
}

.mailbox-list .mail-list li .mxItemView.mail-read .mx-body .mx-subject .title {
  font-size: 13px;
  font-weight: 400;
}

.mailbox-list .mail-list li .mxItemView.mail-read .mx-action:after {
  display: none;
}

/*
.mailbox-list .mail-list li .mxItemView.select {
  background-color: var(--mxitem-view-select-bg-color);
}
*/

.mailbox-list .mail-list li .mxItemView.removing {
  font-style: italic;
  opacity: 0.5;
}

.mailbox-list .mail-list li .mxItemView.removing .feedback-removing {
  position: absolute;
  right: 80px;
}

/*
.mailbox-list .mail-list li.mxItem.active a:focus {
  outline: 1px dashed red;
}
*/

/*
.mailbox-list .mail-list li .mxItemView.select:before
{
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 4px;
  height: 100%;
  background-color: #2196f377;
}
*/

.mailbox-list .mail-list li.mxItem.active > a:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 4px;
  height: 100%;
  background-color: #2196f3;
}

.mailbox-list .mail-list li .mxItemView.select .mx-trash {
  background-image: -webkit-linear-gradient(
    left,
    #2196f300,
    #e6eeff 50%,
    #e6eeff
  );
}

/*.mailbox-list .mail-list li .mxItemView:hover { -webkit-transform: translateY(1px); -ms-transform: translateY(1px); transform: translateY(1px); box-shadow: 0 0 10px 0 rgba(58,70,93,.25); -webkit-transition: all .2s; transition: all .2s; z-index: 5; }*/
/* .no-touch enables for desktop */
.no-touch .mailbox-list .mail-list li .mxItemView:hover .mx-trash {
  display: flex;
}

.no-touch mailbox-list .mail-list li .mxItemView:hover .mx-action .favorite i {
  color: #d3d6da;
}

.no-touch
  .mailbox-list
  .mail-list
  li
  .mxItemView:hover
  .mx-action
  .favorite.open
  i {
  color: #fdac41;
}

.mailbox-list
  .mail-list
  li
  .mxItemView:hover
  .mx-action
  .checkbox
  input[type="checkbox"] {
  border-color: var(--checkbox-border-color);
}

/* mailbox-preview */
.mailbox .mailbox-detail .mailbox-preview {
  float: left;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
}

.mail-content {
  position: relative;
  background: var(--white-space-bg-color);
  border-bottom: 1px solid var(--border-color);
  overflow-y: scroll;
  display: none;
}

.mail-content .mail-content-title {
  padding: 15px 0 0 20px;
  min-height: 45px;
  line-height: 1.5em;
  font-size: 16px;
  font-weight: 600;
  word-break: break-word;
}

.mail-content .mail-content-title .btn {
  border: none;
}

.mail-content .mail-content-title .btn:hover {
  background: var(--action-link-hover-bg-color);
}

.mail-content .mail-content-title .btn:focus {
  background: var(--action-link-hover-bg-color);
}

.mail-content .mail-content-header {
  padding: 10px 20px;
  border-bottom: 1px solid var(--border-color);
  display: flex;
}

.mail-content .mail-content-header .btn {
  border: none;
}

.mail-content .mail-content-header .ch-name {
  float: left;
  width: calc(100% - 70px);
}

.p-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.p-main .icon {
  font-size: 9px;
  font-weight: bolder;
  border: 1px solid var(--primary-text-color);
  border-radius: 10px;
  padding: 2px 5px;
}

@media (max-width: 1024px) {
  .p-main {
    flex-wrap: wrap;
  }

  body .mail-content .mail-content-header .ch-name .ml-title p.p2 {
    padding-left: 0px;
  }
}

.mail-content .mail-content-header .ch-name .ml-avatar {
  float: left;
  width: 42px;
  height: 42px;
  overflow: hidden;
  background-color: var(--ml-avatar-bg-color);
  border: 1px solid var(--border-color);
  /* color: #111; */
  position: relative;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 0.8rem;
  text-align: center;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.mail-content .mail-content-header .ch-name .ml-avatar img {
  height: 42px;
  width: 42px;
}

.mail-content .mail-content-header .ch-name .ml-title {
  float: left;
  width: calc(100% - 60px);
  line-height: 1.6;
}

.mail-content .mail-content-header .ch-name .ml-title p {
  margin: 0;
}

.mail-content .mail-content-header .ch-name .ml-title p.p1 {
  font-weight: 600;
  display: inline-block;
  margin-right: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 50%;
}

.mail-content .mail-content-header .ch-name .ml-title p.p1 small {
  margin-left: 10px;
}

.mail-content .mail-content-header .ch-name .ml-title p.p2 {
  position: relative;
  font-size: 11px;
  color: #828d99;
  padding-left: 10px;
  -webkit-user-drag: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mail-content .mail-content-header .ch-name .ml-title p.p2 span {
  position: absolute;
  left: 0;
  top: 0;
  line-height: 1;
  font-size: 16px;
  -webkit-user-drag: none;
  cursor: pointer;
}

.mail-content .mail-content-header .ch-name .ml-title p.p2 small {
  display: inline-block;
  position: relative;
  margin-left: 20px;
  background: var(--primary-bg-color);
  border-radius: 4px;
  padding: 2px 4px;
  -webkit-user-drag: none;
}

.mail-content .mail-content-header .ch-name .ml-title p.p2 small:before {
  position: absolute;
  content: "";
  left: -12px;
  top: 7px;
  width: 4px;
  height: 4px;
  background: #828d99;
  border-radius: 50%;
}

.mail-content .mail-content-header .ch-name .ml-title p.p3 {
  font-size: 13px;
}

.mail-content .mail-content-header .ch-name .ml-title p.p3 > span {
  font-weight: 600;
  display: inline-block;
  width: 40px;
}

.mail-content .mail-content-header .ch-tool {
  float: right;
  width: 70px;
  justify-content: flex-end;
}

.mail-content .mail-content-header .ch-tool i {
  display: inline-block;
  font-size: 20px;
}

.mail-content .mail-content-header .ch-tool .btn-icon {
  line-height: 14px;
  padding: 0 5px;
  height: 32px;
  text-align: center;
  border: none;
}

.mail-content .mail-content-header .ch-tool .btn-icon:hover,
.mail-content .mail-content-header .ch-tool .btn-icon:focus {
  background: var(--action-link-hover-bg-color);
}

.mail-content .mail-content-header .ch-tool .dropdown-toggle::after {
  display: none;
}

.mail-content .mail-content-body {
  padding: 20px;
  padding-right: 35px;
}

.attachments {
  padding: 20px 0 0;
}

.attachments .download-all {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  color: #919191cc;
  font-size: 0.9em;
  cursor: default;
  font-size: 12px;
  margin-top: 10.5px;
}

.attachments .download-all img {
  vertical-align: middle;
  margin-right: 7px;
}

.attachments .download-all .attachment-number {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 16px;
}

.attachments .download-all .download-all-action {
  cursor: pointer;
  color: #2170f4;
}

.attachments .attachments-area {
  padding-top: 17px;
  margin-left: -14px;
  margin-right: -14px;
  cursor: default;
}

.attachments .attachments-area .file-attachment-item {
  cursor: default;
  display: inline-block;
  position: relative;
  font-size: 13.02px;
  margin: 0 0 8px 14px;
  min-width: 245px;
  width: 245px;
}

.attachments .attachments-area .file-attachment-item .inner {
  border: 1px solid var(--border-color);
  border-radius: 4px;
  color: var(--attachment-inner-text-color);
  background: var(--white-space-bg-color);
  overflow: hidden;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  height: 44px;
}

.attachments .attachments-area .file-attachment-item:hover .inner {
  background-color: #7070701a;
  cursor: pointer;
}

.attachments .attachments-area .file-attachment-item .file-info-wrap {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-positive: 2;
  flex-grow: 2;
  min-width: 85%;
  padding: 10px;
  height: 105%;
  border: 1px solid #00000017;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-right: none;
  border: none;
  font-weight: 500;
  letter-spacing: -0.1px;
  padding: 10px 6px;
}

.attachments
  .attachments-area
  .file-attachment-item
  .file-info-wrap
  .file-icon {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-right: 8px;
}

.attachments
  .attachments-area
  .file-attachment-item
  .file-info-wrap
  .file-detail {
  overflow: hidden;
}

.attachments
  .attachments-area
  .file-attachment-item
  .file-info-wrap
  .file-name {
  display: block;
  font-size: 12px;
  line-height: 1.33;
  font-weight: 500;
  -ms-flex: 1 1;
  flex: 1 1;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 235px;
}

.attachments .attachments-area .file-attachment-item .file-info-wrap .file-size,
.attachments
  .attachments-area
  .file-attachment-item
  .file-info-wrap
  .file-status {
  font-size: 10px;
  margin-left: auto;
  margin-right: 10.5px;
  display: block;
  line-height: 1.4;
  color: #888;
}

.attachments .attachments-area .file-attachment-item .file-action-icons {
  display: -ms-flexbox;
  display: flex;
  border: none;
  opacity: 0;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 0;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(1%, #f0f0f000),
    color-stop(40%, #f0f0f0)
  );
  background-image: -webkit-linear-gradient(left, #f0f0f000 1%, #f0f0f0 40%);
  background-image: -o-linear-gradient(
    left,
    hsla(0, 0%, 94%, 0) 1%,
    #f0f0f0 40%
  );
  background-image: var(--attachment-file-action-icons-bg-image);
  min-width: 50px;
  padding-left: 20px;
  cursor: pointer;
}

.attachments .attachments-area .file-attachment-item .file-action-icon {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-preferred-size: 38px;
  flex-basis: 38px;
  margin-left: auto;
  padding: 10px;
  height: 42px;
  width: 114px;
  border: 1px solid #00000017;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border: none;
  padding: 4px;
}

.attachments .attachments-area .file-attachment-item .file-action-icon .btn {
  border: none;
}

.attachments
  .attachments-area
  .file-attachment-item
  .file-action-icons
  .file-action-icon:last-child {
  padding-right: 8px;
}

.attachments
  .attachments-area
  .file-attachment-item
  .file-action-icons
  .file-action-icon:first-child {
  padding-left: 8px;
}

.attachments
  .attachments-area
  .file-attachment-item
  .file-action-icons
  .file-action-icon.hover-feedback
  img {
  border-radius: 4px;
  width: 30px;
  height: 24px;
}

.attachments .attachments-area .file-attachment-item:focus .file-action-icon,
.attachments .attachments-area .file-attachment-item:focus .file-info-wrap,
.attachments
  .attachments-area
  .file-attachment-item:focus
  .file-thumbnail-preview {
  border-color: #5ea1e7;
}

.attachments .attachments-area .file-attachment-item:hover .file-action-icons {
  opacity: 1;
}

@media (max-width: 1024px) {
  .attachments .attachments-area .file-attachment-item .file-action-icons {
    opacity: 1;
  }
}

.mail-content .mail-content-footer {
  padding: 20px;
  border-top: 1px solid var(--border-color);
}

.mail-content .mail-content-footer > .btn > span {
  padding-left: 8px;
  display: inline-block;
}

.mail-content .mail-content-footer > span {
  color: #bdbdbd;
  cursor: pointer;
  padding-left: 15px;
}

.mail-content-answer {
  border-top: 1px solid var(--border-color);
  display: none;
}

.mail-content.open {
  height: calc(100vh - 113px);
  display: block;
}

.mail-content.print {
  height: auto;
  display: block;
  padding: 5px;
}

@media print {
  .mail-content-header {
    border-bottom: 1px solid #dfe3e7 !important;
  }
}

.mail-content.preview {
  height: 100vh;
}

.mailbox-preview.group .mail-content {
  overflow-y: inherit;
}

.mailbox-preview.group .mail-content .mail-content-body {
  overflow: hidden !important;
}

.mailbox .mailbox-detail .mailbox-preview.group {
  height: calc(100vh - (5px + (53px * 2)));
}

.mail-group-title {
  background: var(--white-space-bg-color);
  overflow: hidden;
  padding: 15px 20px;
  display: flex;
  border-bottom: 1px solid var(--border-color);
  width: 100%;
}

.mail-group-title .thumb {
  margin-right: 10px;
  background-color: #296ef3;
  color: #fff;
  border-radius: 4px;
  font-size: 11px;
  font-weight: 600;
  width: 22px;
  min-width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mail-group-title .name {
  margin-right: 10px;
  width: calc(40% - 26px);
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mail-group-title .text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--mailgroup-title-text-color);
  width: calc(60% - 80px);
}

.mail-group-title .date {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--mailgroup-title-text-color);
  font-size: 11px;
  width: 80px;
  text-align: right;
}

.mail-group-title + .mail-content {
  margin-top: 10px;
  border-top: 1px solid var(--border-color);
}

.mail-content + .mail-content {
  margin-top: 10px;
  border-top: 1px solid var(--border-color);
}

/* mailbox-preview-empty */
.mailbox .mailbox-detail .mailbox-preview-empty,
.documents-layout .mailbox-preview-empty,
.contacts-list__container .mailbox-preview-empty {
  width: 100%;
  font-size: 16px;
  -webkit-user-drag: none;
  height: calc(100vh - 120px);
  overflow-y: auto;
  background-color: var(--empty-bg-color);
  color: var(--empty-text-color);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: center;
  align-self: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-direction: column;
  flex-direction: column;
  font-weight: 200;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.mailbox .mailbox-detail .mailbox-preview-empty > img {
  margin-bottom: 10px;
  max-width: 90%;
}

.mailbox-bottom-info {
  text-align: center;
  padding: 15px 15px 50px;
}

.mailbox-bottom-info span {
  cursor: pointer;
  margin: 0 0.5rem !important;
  color: var(--primary-text-color);
}

.mailbox-bottom-info span:hover {
  font-weight: 400;
}

.mailbox .mailbox-detail .mailbox-preview-empty .mailbox-quota {
  font-size: 14px;
  color: var(--primary-text-color);
  font-weight: 400;
  margin-top: 20px;
}

.mailbox .mailbox-detail .mailbox-preview-empty .mailbox-quota .progress {
  max-width: 302px;
  margin: 0 auto;
}

.mailbox .mailbox-detail .mailbox-preview-empty .mailbox-quota .btn {
  border: none;
}

/* rightClickMenu */
.context-menu-list {
  padding: 0 !important;
  z-index: 9999 !important;
  max-width: 530px !important;
  border: 1px solid var(--border-color) !important;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2) !important;
}

.context-menu-list .context-menu-item {
  padding: 0 !important;
}

.rightClickMenu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.rightClickMenu ul li a {
  position: relative;
  white-space: nowrap;
  font-size: 15px;
  height: 32px;
  display: block;
  width: 100%;
  color: var(--primary-text-color);
  padding: 4px 15px 4px 43px;
}

.rightClickMenu ul li a:hover {
  background-color: var(--primary-bg-color);
  color: #828d99;
  text-decoration: none;
}

.rightClickMenu ul li a i {
  position: absolute;
  left: 15px;
  top: 6px;
  font-size: 20px;
}

.rightClickMenu ul li.seperator {
  background: var(--border-color);
  height: 1px;
  width: 100%;
  margin: 4px 0;
}

/* Splitter */
.splitter_panel .vsplitter {
  background: transparent !important;
  background: #fff;
  height: 100%;
  width: 1px !important;
}

.popover {
  border: 0;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.32);
}

.track-popover {
  padding: 10px;
  min-width: 250px;
}

.track-popover > b {
  display: block;
  margin-bottom: 10px;
  font-weight: 600;
}

.track-popover p {
  display: block;
  margin: 3px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
}

.track-popover p span {
  display: inline-block;
  color: #828d99;
  margin-left: 10px;
}

.track-popover p b {
  flex: 1 1 auto;
  margin-left: 5px;
  font-weight: 600;
}

.track-popover p small {
  font-size: 100%;
  color: #92a58c;
}

.track-popover p small i {
  letter-spacing: -6px;
}

.track-popover p small i + i {
  margin-right: 6px;
}

.track-popover p:last-child small {
  color: #216ef4;
}

/* compose */
.compose-modal {
  z-index: 999;
}

.external-tab.modalCompose.open {
  width: 100% !important;
  max-width: 100% !important;
  align-self: unset;
  height: 100vh;
}

.dock {
  bottom: 0;
  right: 0;
  left: 0;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -ms-flex: 1 1;
  flex: 1 1;
  pointer-events: none;
  z-index: 1004;
}

.dock:has(.external-tab) {
  justify-content: flex-start;
}

.dock.fullscreen {
  z-index: 9999;
}

.dock,
.dock .dock-inner-wrap {
  display: -ms-flexbox;
  display: flex;
  overflow-x: unset;
  overflow-y: unset;
}

.modalCompose {
  max-width: 600px;
  -ms-flex-item-align: end;
  align-self: flex-end;
  margin-right: 10px;
  -webkit-transition: background 0.3s ease-out;
  -o-transition: background 0.3s ease-out;
  transition: background 0.3s ease-out;
  background-color: #0000;
}

.modalCompose.open {
  max-width: 600px;
  width: 600px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.modalCompose.hide {
  display: none;
}

.modalCompose.expand {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10003;
  background-color: #00000080;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: none;
  width: 100%;
  margin-right: 0;
  cursor: default;
  height: 100%;
  pointer-events: all;
}

.modalCompose .compose-dialog {
  position: relative;
  text-align: left;
  width: inherit;
  display: inline-block;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  pointer-events: auto;
  -webkit-transition: all 0.3s ease-out, width 0s;
  -o-transition: all 0.3s ease-out, width 0s;
  transition: all 0.3s ease-out, width 0s;
  vertical-align: bottom;
}

.external-tab .compose-dialog {
  position: relative;
  height: 100% !important;
  display: flex;
  flex-direction: column;
}

.external-tab .compose-dialog .compose-title {
  height: 45px;
  border-radius: 0;
}

.external-tab .compose-dialog .compose-body {
  flex: 1;
  max-height: 100% !important;
}

.compose-grouped {
  overflow: auto;
  flex: 1;
}

.modalCompose.expand .compose-dialog {
  width: 80%;
  border-radius: 4px;
  -webkit-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  transform: translateY(-10px);
  overflow: hidden;
}

.modalCompose .compose-inner-title {
  cursor: pointer;
  overflow: hidden;
}

.modalCompose .compose-error-message ul li {
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
}

.modalCompose .compose-title {
  background: var(--sidebar-bg-color);
  border-bottom: 1px solid var(--border-color);
  color: var(--sidebar-text-color);
  padding: 11px 16px;
  min-width: 200px;
  font-weight: 700;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 4px 4px 0 0;
}

.modalCompose .compose-title .c-title {
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
}

.modalCompose .compose-title .c-action {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.modalCompose .compose-dialog .compose-title .c-action > * {
  margin-left: 10px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
}

.modalCompose .compose-dialog .compose-title .c-action > :hover {
  background-color: #ffffff1a;
}

.modalCompose:not(.open) .compose-title {
  width: 260px;
  margin-bottom: -1px;
}

.modalCompose .compose-body {
  margin-bottom: 56.5px;
  display: none;
  max-height: 543.5px;
  background: var(--compose-body-bg-color);
  overflow-y: auto;
}

.modalCompose.open .compose-body {
  display: block;
}

.modalCompose .dropdown-item {
  padding: 0.25rem 0.5rem;
}

.modalCompose .dropdown-item i {
  position: inherit;
  left: inherit;
  top: inherit;
  font-size: inherit;
}

.compose-inner {
  overflow: auto;
  height: 100%;
  -ms-flex-direction: column;
  flex-direction: column;
  display: flex;
}

.compose-inner form {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.compose-inner .compose-content {
  padding: 0;
  -ms-flex: 1 1;
  flex: 1 1;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -ms-flex-flow: column;
  flex-flow: column;
  height: 100%;
}

.compose-inner .compose-content .compose-centered {
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex: 1 1;
  flex: 1 1;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding-top: 9.8px;
  max-width: none;
  min-height: calc(70vh - 104px);
}

.composer-header {
  position: relative;
  height: 100%;
  width: 100%;
}

.compose-header .compose-participant-field {
  position: relative;
  margin: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding: 0 22px;
  min-height: 46px;
  color: var(--primary-text-color);
}

.compose-header .compose-participant-field:after {
  width: calc(100% - 44px);
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 23px;
  content: " ";
  background: #ddd;
}

.compose-header .compose-participant-field:after,
.compose-header .compose-subject:after {
  background-color: var(--compose-subject-after-bg-color);
}

.compose-header .compose-participant-field .form-control {
  width: 100%;
  border: 0;
  height: 41px;
  padding-left: 70px;
  background-color: var(--compose-body-bg-color);
}

.compose-header .compose-participant-field .form-control:focus {
  outline: none;
  box-shadow: none;
}

.compose-header .compose-participant-field span {
  position: absolute;
  cursor: pointer;
  top: 8px;
  left: 23px;
  display: inline-block;
  text-transform: capitalize;
  /*text-align: center;background: #f3f3f3;*/
  text-wrap: nowrap;
  width: 60px;
  border-radius: 4px;
  padding: 3px 5px;
}

.compose-header .compose-participant-field .dropdown {
  position: absolute;
  cursor: pointer;
  top: 6px;
  left: 23px;
  display: inline-block;
  text-transform: capitalize;
  text-align: center;
  width: 50px;
  border-radius: 4px;
}

.compose-header .compose-participant-field .dropdown .dropdown-item {
  padding-left: 34px;
}

.compose-header .compose-participant-field .custom-field {
  position: absolute;
  cursor: pointer;
  top: 15px;
  right: 32px;
  display: inline-block;
  text-transform: capitalize;
  text-align: center;
}

.compose-header .compose-participant-field .custom-field:after {
  position: absolute;
  content: "";
  right: -12px;
  top: 3px;
  display: inline-block;
  border-bottom: 1px solid;
  border-right: 1px solid;
  transition: transform 0.3s;
  transform: scale(0.75) rotate(45deg);
  width: 8px;
  height: 8px;
  color: var(--primary-text-color);
}

.compose-header .compose-participant-field .custom-field.open:after {
  transform: translateY(4px) scale(0.75) rotate(-135deg);
}

@media (max-width: 767.98px) {
  .compose-header .compose-participant-field .custom-field {
    font-size: 0;
  }

  .compose-header .compose-participant-field .custom-field:after {
    top: 0;
    border-width: 2px;
    width: 12px;
    height: 12px;
  }
}

.compose-header .hidden-fields {
  display: none;
}

.compose-header .hidden-fields.open {
  display: block;
}

.compose-text {
  flex: 1 1;
  cursor: text;
  overflow: hidden;
  position: relative;
  margin: 0;
  padding: 0 20px;
  display: flex;
  align-items: stretch;
  width: 100%;
}

.compose-text-wrap {
  position: relative;
  flex-grow: 2;
  display: flex;
  align-items: stretch;
  -ms-flex-direction: column;
  flex-direction: column;
}

.modalCompose.open .compose-text {
  height: 100%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  width: 100%;
}

.modalCompose.open .compose-text > .form-control {
  font-family: monospace;
}

.compose-inner .compose-action {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  -ms-flex-direction: row;
  flex-direction: row;
  border-top: 1px solid var(--border-color);
  padding: 11px 22.5px;
  width: 100%;
  background: var(--compose-body-bg-color);
}

.compose-inner .compose-action .btn {
  font-weight: 600;
}

.btn-dark {
  color: #fff;
  background-color: #4a4f5f;
  border-color: #4a4f5f;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 992px) {
  .btn-dark {
    margin: 0 12px;
    width: auto;
  }

  .btn-dark i {
    margin-right: 5px;
  }
}

.compose-inner .compose-action div {
  flex: 1 1 0%;
}

/* card-list */
.card-list {
  list-style: none;
  padding: 10px 0;
  margin: -11px;
}

.card-list .list-header {
  padding: 0.625rem 0.9375rem;
  min-height: 2.5rem;
  display: flex;
}

.card-list .list-header .avatar {
  background-color: #296ef3;
  color: #fff;
  border-radius: 4px;
  align-items: center;
  display: flex;
  flex-shrink: 0;
  margin-right: 10px;
  font-size: 13px;
  font-weight: 600;
  height: 2.5rem;
  justify-content: center;
  overflow: hidden;
  position: relative;
  text-transform: uppercase;
  width: 2.5rem;
}

.card-list .list-header span {
  display: block;
  font-weight: 400;
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
}

.card-list .list-separator {
  height: 1px;
  width: 100%;
  background-color: #cccccc80;
}

.card-list .list-item {
  line-height: 1;
  padding: 10px 15px;
  cursor: pointer;
}

.card-list .list-item:hover {
  background-color: #f8f9fa;
}

/* modal-popup */
.modal-body .form-group label {
  margin-bottom: 0;
}

.modal-body .button-group {
  text-align: right;
}

.modal-body .button-group .btn {
  font-weight: 500;
}

.modal-body .button-group .btn-light {
  background: transparent;
  border: 0;
  color: #5f6368;
}

/* modal-delay */
#modalDelay h5 span {
  display: block;
  font-size: 14px;
  font-weight: normal;
  opacity: 0.7;
}

.delayMain {
  position: relative;
}

.delayMain ul {
  list-style: none;
  padding: 0;
  margin: -14px;
}

.delayMain ul li a {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 14px;
  width: 100%;
  padding: 8px 15px;
  color: var(--primary-text-color);
}

.delayMain ul li a span {
  display: flex;
  align-items: flex-start;
}

.delayMain ul li a small {
  display: flex;
  align-items: flex-end;
  font-size: 100%;
}

.delayMain ul li a i {
  font-size: 22px;
  margin-right: 5px;
  position: relative;
}

.delayMain ul li a:hover {
  background-color: #3c40430a;
  text-decoration: none;
}

.delayMain ul li:last-child a {
  justify-content: flex-start;
  border-top: 1px solid #ebebeb;
  margin-top: 8px;
  padding-bottom: 12px;
  padding-top: 12px;
}

/* modal-version */
#modalVersion .versionMain {
  height: 300px;
  overflow-y: scroll;
}

#modalVersion .versionMain h1 {
  font-weight: 600;
  font-size: 1.25rem;
  color: #5e5e5e;
  margin: 0 0 5px;
}

#modalVersion .versionMain h2 {
  font-weight: 400;
  font-size: 0.875rem;
  text-transform: uppercase;
  color: #919191;
  margin: 0 0 1rem;
}

#modalVersion .versionMain p em:first-of-type {
  font-size: 12px;
  font-style: normal;
  line-height: 1rem;
  color: #0a4c73;
  border-radius: 2px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #ceeafb;
  padding: 1px 9px;
}

#modalVersion .versionMain ul {
  font-size: 0.875rem;
  color: #787878;
  padding: 0 0 0 1.5rem;
  margin: 0 0 1rem;
}

#modalVersion .versionMain p {
  margin: 0 0 0.5rem;
}

/* modern-alert */
.modern-alert {
  box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.302),
    0 4px 8px 3px rgba(60, 64, 67, 0.149);
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  letter-spacing: 0.2px;
  -webkit-align-items: center;
  align-items: center;
  background-color: #202124;
  border: none;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  bottom: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  font-weight: 400;
  left: 240px;
  z-index: 1001;
  margin: 24px;
  max-width: 640px;
  min-height: 52px;
  padding: 8px 24px;
  padding-right: 52px;
  position: fixed;
  right: auto;
  text-align: left;
  top: auto;
  white-space: normal;
}

.modern-alert .modern-main {
  font-size: 14px;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
}

.modern-alert .modern-main span {
  align-items: center;
  border: none;
  display: inline-flex;
  justify-content: center;
  outline: none;
  position: relative;
  z-index: 0;
  -webkit-font-smoothing: antialiased;
  margin-right: 20px;
}

.modern-alert .modern-main a {
  align-items: center;
  border: none;
  display: inline-flex;
  justify-content: center;
  outline: none;
  position: relative;
  z-index: 0;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.25px;
  background: none;
  border-radius: 4px;
  box-sizing: border-box;
  color: #5f6368;
  cursor: pointer;
  font-weight: 500;
  height: 36px;
  outline: none;
  color: #8ab4f8;
  margin-left: 8px;
  min-width: auto;
  padding: 0 8px;
  text-decoration: none;
}

.modern-alert .modern-main a:before {
  content: "";
  display: block;
  opacity: 0;
  position: absolute;
  transition-duration: 0.15s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  z-index: -1;
  width: 62px;
  height: 34px;
  bottom: 0;
  left: 0;
  top: 2px;
  background: none;
  border-radius: 4px;
  box-sizing: border-box;
  transform: scale(0);
  transition-property: transform, opacity;
}

.modern-alert .modern-main a:hover:before {
  background-color: #fff3;
  border: none;
  box-shadow: none;
  opacity: 1;
  transform: scale(1);
}

.modern-alert .modern-main a:hover {
  text-decoration: none;
}

.modern-alert .m-close {
  position: relative;
  font-size: 20px;
  border-radius: 50%;
  color: #fff;
  align-items: center;
  border: none;
  display: inline-flex;
  justify-content: center;
  outline: none;
  z-index: 0;
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 16px;
}

.modern-alert .m-close:before {
  content: "";
  display: block;
  opacity: 0;
  position: absolute;
  transition-duration: 0.15s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  z-index: -1;
  bottom: -10px;
  left: -10px;
  right: -10px;
  top: -10px;
  background: none;
  border-radius: 50%;
  box-sizing: border-box;
  transform: scale(0);
  transition-property: transform, opacity;
}

.modern-alert .m-close:after {
  content: "";
  height: 200%;
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
}

.modern-alert .m-close:hover:before {
  background-color: #fff3;
  border: none;
  box-shadow: none;
  opacity: 1;
  transform: scale(1);
}

.modern-alert .m-close:hover {
  text-decoration: none;
}

/* book-contact */
#modalDirectory .modal-body {
  padding: 0;
}

.book-contact-header {
  position: relative;
  overflow: hidden;
  width: 100%;
  border-bottom: 1px solid var(--border-color);
  padding: 11px 20px;
  display: flex;
}

.book-contact-header .thumb {
  margin-right: 10px;
  width: 22px;
  min-width: 22px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.book-contact-header .name {
  margin-right: 10px;
  flex-basis: 50%;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.book-contact-header .email {
  flex-basis: 50%;
  overflow: hidden;
  font-size: 13px;
  color: #00173780;
  display: flex;
  align-items: center;
}

.book-contact {
  position: relative;
  flex-grow: 1;
  max-height: 315px;
  overflow-y: scroll;
}

.book-contact .bc-item {
  position: relative;
  overflow: hidden;
  width: 100%;
  border-bottom: 1px solid var(--border-color);
  padding: 11px 20px;
  display: flex;
}

.book-contact .bc-item .check {
  margin-right: 10px;
  width: 22px;
  min-width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.book-contact .bc-item .thumb {
  margin-right: 10px;
  background-color: #296ef3;
  color: #fff;
  border-radius: 50%;
  font-size: 11px;
  font-weight: 600;
  width: 22px;
  min-width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.book-contact .bc-item .name {
  margin-right: 10px;
  flex-basis: 50%;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.book-contact .bc-item .email {
  flex-basis: 50%;
  overflow: hidden;
  font-size: 13px;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #00173780;
  display: flex;
  align-items: center;
}

.book-contact-footer {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding: 11px 20px;
  display: flex;
}

.book-contact-footer span {
  position: relative;
  padding-left: 22px;
}

.book-contact-footer i {
  position: absolute;
  left: 0;
  top: 1px;
  font-size: 22px;
}

#modalDirectory .modal-body .button-group {
  position: relative;
  padding: 1rem;
}

#modalDirectory .modal-body .button-group span {
  position: absolute;
  left: 23px;
  top: 22px;
  font-size: 13px;
}

.custom-contact-list {
  position: absolute;
  top: 0;
  z-index: 5;
  left: 90px;
  background: #fff;
  width: 100%;
  max-width: 360px;
  border-radius: 4px;
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.4);
  display: none;
}

.custom-contact-list.open {
  display: block;
}

.custom-contact-list .book-contact {
  max-height: 180px;
}

.custom-contact-list .book-contact .bc-item {
  cursor: pointer;
}

.custom-contact-list .book-contact .bc-item:hover {
  background: #f3f3f3;
}

/* modern-preview */
.modern-preview {
  -webkit-transition-property: opacity, visibility;
  transition-property: opacity, visibility;
  -webkit-transition-duration: 0.1s, 0s;
  transition-duration: 0.1s, 0s;
  -webkit-transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  -webkit-transition-delay: 0s, 0.1s;
  transition-delay: 0s, 0.1s;
  font-size: 0.875rem;
  letter-spacing: 0.2px;
  -webkit-align-items: center;
  align-items: center;
  background-color: #000000d9;
  border: none;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: flex-end;
  top: 0;
  left: 0;
  z-index: 1001;
  width: 100%;
  height: 100vh;
  position: fixed;
  text-align: left;
  white-space: normal;
  opacity: 0;
  visibility: hidden;
}

.modern-preview.open {
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  -webkit-transition-delay: 0s, 0s;
  transition-delay: 0s, 0s;
  opacity: 1;
  visibility: visible;
}

.preview-header {
  width: 100%;
  padding: 10px 20px;
  background: linear-gradient(to bottom, #000000a6 0%, #0000 100%);
}

.preview-header a {
  font-size: 28px;
  line-height: 1;
  padding: 8px;
  color: #fff;
}

.preview-header a:hover {
  background: #565656;
  border-radius: 4px;
}

.preview-header span {
  padding: 12px 10px 0;
  font-size: 12px;
}

.preview-header span > img {
  position: relative;
  top: -1px;
}

.preview-main {
  position: relative;
  width: 100%;
  height: 100%;
}

.preview-main .preview-body {
  background: #fff;
  width: 50%;
  overflow-y: scroll;
  height: 100vh;
  margin: 0 auto;
}

.preview-main .preview-footer {
  position: absolute;
  border-radius: 4px;
  left: calc(50% - 135px);
  bottom: 80px;
  height: 44px;
  width: 270px;
  font-size: 15px;
  padding-left: 10px;
  background: #000000bf;
}

.preview-main .preview-footer > div {
  padding: 5px;
}

.preview-main .preview-footer > div + div:before {
  position: absolute;
  content: "";
  right: 55%;
  top: 0;
  height: 44px;
  width: 1px;
  background: #6f6f6f;
}

.preview-main .preview-footer a {
  font-size: 24px;
  line-height: 1;
  padding: 5px 10px;
  color: #fff;
}

.preview-main .preview-footer a:hover {
  background: #6f6f6f;
  border-radius: 4px;
}

.note-editor .dropdown-toggle::after {
  display: inline-block !important;
}

.note-editor.note-frame {
  border: 0;
}

.note-editable.panel-body {
  overflow-y: scroll;
}

.note-popover .popover-content .dropdown-menu,
.card-header.note-toolbar .dropdown-menu {
  overflow-y: scroll;
  max-height: 268px;
}

.note-popover .popover-content,
.card-header.note-toolbar {
  background: #fff;
  border: 0 !important;
  position: relative;
  z-index: 1;
}

.cke_editable {
  margin: 0 !important;
}

.cke_top {
  background: transparent !important;
  padding: 6px 0 !important;
  border: none !important;
}

.cke_contents {
  flex: 1;
}

.cke_chrome {
  border: none !important;
  height: 100% !important;
}

.cke_inner {
  display: flex !important;
  flex-direction: column !important;

  height: 100% !important;
}

.cke_bottom {
  display: none !important;
}

.cke_reset_all,
.cke_reset_all *,
.cke_reset_all a,
.cke_reset_all textarea {
  color: #707070 !important;
}

.ck.ck-editor__main > .ck-editor__editable:focus {
  border: 0 !important;
  outline: none !important;
}

.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  outline: none !important;
  border: 0 !important;
  border-color: #fff !important;
}

.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
  box-shadow: none !important;
}

.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
  border: 0 !important;
}

.ck.ck-toolbar {
  background: #0000 !important;
  border: 0 !important;
}

.ck.ck-toolbar .ck.ck-toolbar__separator {
  background: #64798f1f !important;
  margin-top: 10px !important;
  height: 20px;
}

.mail-content-answer .ck-editor__editable {
  min-height: 160px;
}

.modalCompose.open .ck-editor__editable {
  height: calc(100vh - 450px);
}

.modalCompose.expand .ck-editor__editable {
  min-height: 200px;
  max-height: calc(100vh - 450px);
}

.ck.ck-editor {
  max-width: 100%;
}

.ck.ck-dropdown__panel {
  max-height: 150px;
  overflow-y: auto;
}

.tag-editor {
  padding: 8px 5px 0 56px;
  border: 0;
  line-height: 24px;
  border-radius: 4px;
}

.tag-editor .tag-editor-tag {
  border-radius: 4px 0 0 4px;
  background: var(--primary-bg-color);
  color: #222;
}

.tag-editor .tag-editor-delete {
  background: var(--primary-bg-color);
  color: #222;
}

@media (min-width: 1094px) {
  .search-header {
    width: 554px;
  }

  .action-header {
    width: calc(100% - 800px);
  }
}

@media (min-width: 992px) {
  .mobile-header {
    display: none;
  }

  .mobile-footer {
    display: none;
  }

  .mobileSearch {
    display: none;
  }

  .mobile-new-compose {
    display: none;
  }

  .header {
    display: block;
  }

  .sidebar {
    transform: translateX(0);
  }

  .sidebar-fixed + .mailbox {
    margin-left: 240px;
    margin-top: 60px;
    height: calc(100vh - 60px);
  }

  .mailbox {
    width: calc(100% - 240px);
  }

  .mailbox .mailbox-title {
    display: flex;
  }

  .mailbox .mailbox-title {
    width: calc(100% - 240px);
    margin-left: 240px;
  }

  .mailbox .mailbox-title .mailbox-search .form-control {
    width: 360px;
  }

  .mailbox .mailbox-detail {
    margin-top: 53px;
  }

  .mailbox .mailbox-detail .mailbox-list.default {
    height: calc(100vh - 113px);
    padding-bottom: 20px;
  }

  .mailbox .mailbox-detail .mailbox-list.vertical {
    width: 50%;
    height: calc(100vh - 113px);
    border-right: 1px solid var(--border-color);
  }

  .mailbox .mailbox-detail .mailbox-list.vertical .mail-list {
    height: calc(100% - 39px);
  }
  .mailbox .mailbox-detail .mailbox-list.vertical .mail-list.has-search {
    height: calc(100% - 100px);
  }

  .mailbox
    .mailbox-detail
    .mailbox-list.vertical
    .mail-list
    li
    .mxItemView:hover
    .mx-trash {
    display: flex;
  }

  .mailbox .mailbox-detail .mailbox-preview.vertical {
    width: 50%;
  }

  .mailbox .mailbox-detail .mailbox-preview.vertical .mailbox-preview-empty {
    height: calc(100vh - 113px);
  }

  .mailbox .mailbox-detail .mailbox-preview.vertical .mail-content {
    height: calc(100vh - 113px);
  }

  .mailbox .mailbox-detail .mailbox-preview.group .mail-content {
    height: auto;
  }

  .mailbox .mailbox-detail .mailbox-list.horizontal {
    width: 100%;
    height: calc(50vh - 104px);
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 40px;
  }

  .mailbox .mailbox-detail .mailbox-list.horizontal .mail-list {
    height: 100%;
  }

  .mailbox
    .mailbox-detail
    .mailbox-list.horizontal
    .mail-list
    li
    .mxItemView:hover
    .mx-trash {
    display: flex;
  }

  .mailbox .mailbox-detail .mailbox-preview.horizontal {
    width: 100%;
    height: 50vh;
  }

  .mailbox .mailbox-detail .mailbox-preview.horizontal .mailbox-preview-empty {
    height: 100%;
    justify-content: flex-start;
    padding: 30px 0;
  }

  .mailbox .mailbox-detail .mailbox-preview.horizontal .mail-content {
    height: 99%;
  }

  /* minimum */
  .sidebar-fixed.minimum + .mailbox {
    margin-left: 62px;
    margin-top: 60px;
    height: 100vh;
  }

  .mailbox.minimum {
    width: calc(100% - 62px);
    margin-left: 62px !important;
  }

  .mailbox.minimum .mailbox-title {
    width: calc(100% - 62px);
    margin-left: 62px;
  }

  /* app */
  .sidebar-app.open {
    display: block;
  }

  .mailbox.app {
    width: calc(100% - 315px);
  }

  .mailbox.app .mailbox-title {
    width: calc(100% - 315px);
    margin-left: 240px;
  }

  .mailbox.app .mailbox-footer {
    width: calc(100% - 315px);
    margin-left: 240px;
    bottom: 0;
  }

  /* minimum & app */
  .sidebar-app.open {
    display: block;
  }

  .mailbox.minimum.app {
    width: calc(100% - 122px);
  }

  .mailbox.minimum.app .mailbox-title {
    width: calc(100% - 137px);
    margin-left: 62px;
  }
}

@media only screen and (max-width: 767px) {
  .mail-content.open {
    height: auto;
    display: block;
  }

  .mail-content .mail-content-title {
    padding: 10px 10px 0;
    margin-right: 0;
    line-height: 1.3;
    word-break: break-word;
  }

  .mail-content .mail-content-title .ml-subject {
    line-height: 1.3;
  }

  .mail-content .mail-content-title .btn {
    display: none;
  }

  .mail-content .mail-content-header {
    display: grid;
    padding: 10px;
  }

  .mail-content .mail-content-header .ch-name {
    width: 100%;
    overflow: hidden;
  }

  .mail-content .mail-content-header .ch-tool {
    width: 100%;
    display: flex;
  }

  .mailbox .mailbox-detail .mailbox-preview {
    display: none;
  }

  .mailbox .mailbox-detail .mailbox-preview-empty {
    padding: 0 30px;
  }

  .mailbox .mailbox-detail .mailbox-preview-empty .mailbox-quota {
    text-align: center;
  }

  .modalCompose {
    margin: 0;
  }

  .modalCompose.open {
    max-width: 100%;
    width: 100%;
  }

  .modalCompose .btn-collapse,
  .modalCompose .btn-minimize,
  .modalCompose .btn-expand {
    display: none;
  }

  .sidebar-app {
    display: none;
  }

  .sidebar-app.open {
    display: none;
  }
}

/* ---------------- Contact List ------------------ */

.contact-list {
  list-style: none;
  padding: 0;
  margin: 0;
  height: 100%;
  overflow-y: scroll;
}

.contact-list .heading {
  background-color: var(--primary-bg-color);
  border-bottom: 1px solid var(--border-color);
  padding: 8px 15px;
  line-height: 1;
  font-size: 12px;
  font-weight: 600;
  color: #828d99;
}

.contact-list .cxItem {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #dedede;
  position: relative;
}

.contact-list .cxItem .cxItemView {
  display: flex;
  width: 100%;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  background: #fff;
}

.contact-list .cxItem:hover .cxItemView {
  background: var(--primary-bg-color);
}

.contact-list .cxItem .cxItemView > div {
  flex-grow: 1;
  flex-basis: 0;
  padding: 16px 10px;
}

.contact-list .cxItem .cxItemView .check {
  flex-grow: 0;
}

.contact-list .cxItem .cxItemView .avatar {
  flex-grow: 0;
  padding: 11px 10px;
}

.contact-list .cxItem .cxItemView .avatar span {
  background-color: #296ef3;
  color: #fff;
  border-radius: 4px;
  font-size: 11px;
  font-weight: 600;
  width: 32px;
  min-width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-list .cxItem .cxItemView .email {
  font-size: 15px;
  font-weight: 600;
}

.contact-list .cxItem .cxItemView .action {
  flex-grow: 0;
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  padding: 12px;
  visibility: hidden;
}

.contact-list .cxItem:hover .action {
  visibility: visible;
}

.contact-list .cxItem .action .btn {
  margin: 0 2px;
  padding: 2px 6px;
  font-size: 16px;
  color: #5a5f64;
}

.contact-list .cxItem .action .dropdown-toggle::after {
  display: none;
}

/* ---------------- Log List ------------------ */

.log-list {
  list-style: none;
  padding: 0;
  margin: 0;
  height: 100%;
  overflow-y: auto !important;
  scrollbar-color: #c0c0c0 transparent !important;
  scrollbar-width: thin !important;
}

.log-list .heading {
  background-color: var(--primary-bg-color);
  border-bottom: 1px solid var(--border-color);
  padding: 8px 0;
  line-height: 1;
  font-size: 12px;
  font-weight: 600;
  color: #828d99;
  display: none;
}

.log-list .heading > div {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
}

.log-list .heading > div > div {
  flex-grow: 1;
  flex-basis: 0;
  padding: 16px 10px;
}

.log-list .heading > div > div.check {
  flex-grow: 0;
}

.log-list .heading > div > div.avatar {
  flex-grow: 0;
  padding: 11px 10px;
}

.log-list .heading > div > div.status {
  flex-grow: 0.5;
  text-align: center;
}

.log-list .heading > div > div.date {
  flex-grow: 1;
  text-align: center;
}

.log-list .cxItem {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid var(--border-color);
  position: relative;
}

.log-list .cxItem .cxItemView {
  display: flex;
  width: 100%;
  font-size: 14px;
  color: var(--primary-text-color);
  cursor: pointer;
  background: var(--white-space-bg-color);
  flex-direction: column;
  align-items: center;
}

.log-list .cxItem:hover .cxItemView {
  background: var(--primary-bg-color);
}

.log-list .cxItem .cxItemView > div {
  flex-grow: 1;
  flex-basis: 0;
  padding: 3px 10px;
}

.log-list .cxItem .cxItemView .check {
  flex-grow: 0;
}

.log-list .cxItem .cxItemView .avatar {
  flex-grow: 0;
  padding: 11px 10px;
}

.log-list .cxItem .cxItemView .avatar span {
  background-color: #296ef3;
  color: #fff;
  border-radius: 4px;
  font-size: 11px;
  font-weight: 600;
  width: 32px;
  min-width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.log-list .cxItem .cxItemView .email {
  font-size: 15px;
  font-weight: 600;
  flex-grow: 1;
}

.log-list .cxItem .cxItemView .status {
  flex-grow: 0.5;
  text-align: center;
}

.log-list .cxItem .cxItemView .date {
  flex-grow: 1;
  text-align: right;
  flex-shrink: 0.5;
}

.log-list .cxItem .cxItemView .action {
  flex-grow: 0;
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  padding: 12px;
  visibility: hidden;
}

.log-list .cxItem:hover .action {
  visibility: visible;
}

.log-list .cxItem .action .btn {
  margin: 0 2px;
  padding: 2px 6px;
  font-size: 16px;
}

.log-list .cxItem .action .dropdown-toggle::after {
  display: none;
}

.log-filter {
  padding: 0 15px;
}

@media (min-width: 992px) {
  .log-list .heading {
    display: block;
  }

  .log-list .cxItem .cxItemView {
    flex-direction: inherit;
    align-items: inherit;
  }

  .log-list .cxItem .cxItemView > div {
    padding: 12px 8px;
  }
}

@media screen and (max-width: 768px) {
  .log-list {
    display: grid;
  }

  .log-list .heading {
    display: block;
  }

  .log-list .cxItem .cxItemView {
    flex-direction: inherit;
    align-items: inherit;
  }

  .log-list .cxItem .cxItemView > div {
    padding: 12px 8px;
  }

  .log-list-container {
    -webkit-overflow-scrolling: touch;
    max-width: 100%;
    overflow-x: auto;
  }
}

/* ---------------- Setting ------------------ */
.mailbox-setting {
  position: relative;
  padding: 15px 15px 30px;
  background: var(--white-space-bg-color);
  height: calc(100% - 50px);
  overflow-y: scroll;
}

.mailbox-setting .setting-item {
  padding: 1.5rem 0;
  border-top: 1px solid var(--border-color);
}

.mailbox-setting .setting-item:first-of-type {
  border: none;
}

.mailbox-setting .setting-item .title-info {
  position: relative;
  font-size: 16px;
  line-height: 1;
  font-weight: 600;
}

.mailbox-setting .setting-item .title-info span {
  display: block;
  font-size: 13px;
  line-height: 1.3;
  font-weight: 400;
  opacity: 0.8;
  margin-top: 10px;
}

.mailbox-setting .setting-item .custom-switch {
  margin: 0 0 1rem 0;
}

.mailbox-setting .setting-item .form-group label {
  display: block;
  margin-bottom: 1px;
  font-weight: 600;
}

.settings-pop3 {
  margin-bottom: 12rem;
}

.settings-pop3 .dtp-download_since {
  width: 300px;
}

.ZmShortcutList {
  width: 100%;
  padding: 1rem;
  -moz-columns: 3;
  -webkit-columns: 3;
  columns: 3;
}

.ZmShortcutList DL {
  margin-top: 0;
}

.shortcutListMap .title {
  font-weight: bold;
  padding-bottom: 1rem;
}

.shortcutListMap LH {
  display: block;
}

.shortcutListMap DT {
  display: inline-block;
  text-align: right;
  padding-right: 0;
}

.shortcutListMap.prefs DT {
  min-width: 146px;
  margin-bottom: 4px;
}

.shortcutKeyCombo {
  white-space: nowrap;
}

.shortcutKeyCombo.prefs {
  margin-right: 5px;
  margin-left: 5px;
  font-size: 12px;
}

.shortcutKey {
  font-weight: bold;
  padding: 2px 4px;
}

.shortcutKey.prefs {
  background-color: var(--primary-bg-color);
  border: solid var(--border-color);
  border-width: 1px 2px 3px 1px;
  line-height: 22px;
  margin: 1px;
  color: #5c6268;
}

.shortcutListMap DD {
  display: inline;
  font-size: 12px;
  margin: 0;
}

.shortcutListMap DD:after {
  display: block;
  content: "";
}

.table-bordered {
  border: 1px solid var(--table-border-color);
}

.card {
  color: var(--primary-text-color) !important;
  background-color: var(--empty-bg-color) !important;
}

.table {
  color: var(--primary-text-color) !important;
  background-color: var(--white-space-bg-color) !important;
}

.table > :not(caption) > * > * {
  color: var(--primary-text-color) !important;
  background-color: var(--white-space-bg-color) !important;
}

.table td,
.table th {
  color: var(--primary-text-color) !important;
  border-color: var(--table-border-color) !important;
}

.table2 td,
.table2 th {
  padding: 5px 10px;
  vertical-align: middle;
  border: 0;
  border-top: 1px solid;
  border-color: var(--table-border-color) !important;
  color: var(--table-text-color);
  background: var(--table2-th-bg-color);
}

.table2 thead th {
  background: var(--table2-th-bg-color);
}

.table2 td .btn-icon {
  font-size: 18px;
  line-height: 1;
  padding: 3px 6px;
  border: 1px solid var(--table-border-color);
}

/* ---------------- Statistic ------------------ */
.mailbox-statistic {
  position: relative;
  padding: 15px 15px 30px;
  height: calc(100% - 50px);
  overflow-y: scroll;
}

.mailbox-statistic .statistic-item {
  overflow: hidden;
  background: var(--white-space-bg-color);
  margin-bottom: 30px;
  border: 1px solid var(--border-color);
  height: calc(100% - 30px);
  min-height: 100px !important;
}

.mailbox-statistic .statistic-item .s-title {
  background: var(--statistics-item-title-bg-color);
  font-size: 16px;
  padding: 15px;
  font-weight: 600;
  border-bottom: 1px solid var(--border-color);
  display: flex;
  justify-content: space-between;
}

.mailbox-statistic .statistic-item .s-body {
  position: relative;
  padding: 15px;
}

.mailbox-statistic .statistic-item .s-body.no-padding {
  padding: 0;
}

.mailbox-statistic .statistic-item .s-body.no-padding .table {
  margin-bottom: 0;
}

.mailbox-statistic .statistic-item .s-body.no-padding .table tr:first-child td {
  border-top: 0;
}

.mailbox-statistic .statistic-item .s-body.no-padding .table tr td {
  border-color: var(--table-border-color);
  color: var(--table-text-color);
}

.knob {
  border: 0;
  text-align: center;
}

/* ---------------- Calendar ------------------ */
.rbc-agenda-table td {
  border-width: 1px;
}

.calendar-detail {
  margin-top: 0 !important;
}

.mailbox .mailbox-detail.calendar-detail .mailbox-list {
  height: calc(100% - 60px);
}

.calendar-detail .fc-toolbar.fc-header-toolbar {
  padding: 15px;
  margin-bottom: 0;
}

.calendar-detail .fc-button-primary {
  color: #5a5f64;
  background-color: #ffffff;
  border-color: var(--border-color);
  padding: 6px 10px;
  height: 34px;
  line-height: 1;
}

.calendar-detail .fc-button-primary:disabled {
  color: #5a5f64;
  background-color: #ffffff;
  border-color: var(--border-color);
  padding: 6px 10px;
  height: 34px;
  line-height: 1;
}

.calendar-detail .fc-dayGrid-view td.fc-week-number {
  background-color: #fbfbfb;
  color: var(--primary-text-color);
  border-color: var(--border-color);
}

.fc-day-header,
.fc-week-number.fc-widget-header {
  background-color: #fbfbfb;
}

.fc-view,
.fc-view > table {
  z-index: 0;
}

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-content,
.fc-unthemed .fc-popover,
.fc-unthemed .fc-list-view,
.fc-unthemed .fc-list-heading td {
  border-color: var(--border-color);
}

.calendar-detail #calendar {
  background: #fff;
}

.checkbox-width {
  min-width: 90px !important;
}

.modalEvent {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1001;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background: #fff;
  display: none;
}

.modalEvent.open {
  display: block;
}

.newEventMain .eventSection1 {
  padding: 15px;
  background: #fbfbfb;
  border-bottom: 1px solid #dedede;
}

.newEventMain .eventSection1 h1 {
  font-size: 16px;
  color: #888;
  margin-bottom: 15px;
}

.newEventMain .eventSection1 .col-lg-1 {
  padding-left: 0;
}

.newEventMain .eventSection1 .custom-input .form-control {
  height: 40px;
  font-size: 18px;
  font-weight: 600;
}

.newEventMain .eventSection1 .custom-input .btn {
  height: 40px;
}

.newEventMain .eventSection2 {
  padding: 15px;
}

.newEventMain .eventSection2 h3 {
  color: #888;
  font-size: 16px;
  padding: 0.5rem 0;
}

.newEventMain input[type="checkbox"] {
  position: relative;
  top: 1px;
}

.newEventMain label {
  display: flex;
  align-items: center;
  margin: 5px 0;
  color: #333;
}

.newEventMain .calendar-option-color {
  position: relative;
  top: 4px;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 5px;
  border-radius: 50%;
}

.newEventMain .btn-light {
  border: 1px solid #ced4da;
  background: #fff;
}

.newEventMain .eventSection2 label {
  position: relative;
  padding-left: 25px;
}

.newEventMain .eventSection2 label i {
  position: absolute;
  left: 0;
  top: 2px;
  font-size: 20px;
}

.datetimepicker.dropdown-menu {
  min-width: auto;
  font-size: 13px;
  color: #333;
  padding: 10px;
}

.datetimepicker.dropdown-menu:before {
  border-top: inherit;
  border-left: inherit;
  content: inherit;
  position: inherit;
  top: inherit;
  left: inherit;
  width: inherit;
  height: inherit;
  display: inherit;
  background: inherit;
  -webkit-transform: inherit;
  -ms-transform: inherit;
  transform: inherit;
  z-index: inherit;
  box-sizing: inherit;
}

.datetimepicker td,
.datetimepicker th {
  padding: 4px 6px;
}

.datetimepicker table tr td.new,
.datetimepicker table tr td.old {
  color: #00000040;
}

.datetimepicker table tr td.active,
.datetimepicker table tr td.active.disabled,
.datetimepicker table tr td.active.disabled:hover,
.datetimepicker table tr td.active:hover {
  box-shadow: none;
  background: var(--primary-color);
  background-color: var(--primary-color) !important;
}

.datetimepicker-inline {
  color: #fff;
  font-size: 12px;
  padding: 13px 13px 30px;
}

.datetimepicker-inline table tr td.new,
.datetimepicker-inline table tr td.old {
  color: #ffffff70;
}

.datetimepicker-inline td,
.datetimepicker-inline th {
  padding: 5px 7px;
}

.datetimepicker-inline table tr td.day:hover,
.datetimepicker-inline table tr td.hour:hover,
.datetimepicker-inline table tr td.minute:hover {
  background: #3a395a;
}

@media only screen and (max-width: 767px) {
  .newEventMain .eventSection1 .col-lg-1 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.empty-mail {
  width: 100%;
  height: 100%;
  /* height: calc(100vh - 152px); */
  font-size: 16px;
  -webkit-user-drag: none;
  background-color: var(--empty-bg-color);
  color: var(--empty-text-color);
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  font-weight: 200;
  justify-content: center;
}

.empty-mail img {
  width: 240px;
}

.empty-mail div {
  margin-top: 15px;
}

.compose-attch-main {
  position: relative;
  padding: 10px 25px;
}

.compose-attch {
  position: relative;
  display: flex;
  align-items: center;
  background-color: var(--compose-attachment-bg-color);
  min-height: 35px;
  padding: 5px 10px;
}

.compose-attch + .compose-attch {
  margin-top: 10px;
}

.compose-attch span {
  display: inline-block;
  font-weight: 600;
  margin-right: 10px;
}

.compose-attch span.compose-progress {
  display: inline-block;
  height: 15px;
  line-height: 10px;
  border: solid 1px #999;
  padding: 1px;
  width: 100px;
}

.compose-attch span.compose-progress > span {
  background: #2973e8;
  height: 11px;
}

.compose-attch .attach-indicator,
.compose-attch button {
  position: absolute;
  right: 10px;
  top: 9px;
  width: 16px;
  height: 16px;
  line-height: 1;
  background: #0000;
  border: 0;
  color: #858585;
  padding: 0;
  font-weight: 600;
}

@media only screen and (max-width: 767px) {
  .compose-attch {
    display: block;
  }

  .compose-attch span.compose-progress {
    display: block;
  }

  .mailbox {
    height: calc(100vh - 115px);
  }

  .mobile-header-action {
    display: flex;
  }
}

@media (min-width: 992px) {
  .mailbox
    .mailbox-detail
    .mailbox-list.vertical
    .mail-list
    li
    .mxItemView:hover
    .mx-trash {
    background: var(--mxitem-view-bg-color);
  }

  .mailbox
    .mailbox-detail
    .mailbox-list.vertical
    .mail-list
    li
    .mxItemView.mail-read:hover
    .mx-trash {
    background: var(--white-space-bg-color);
  }

  .mailbox
    .mailbox-detail
    .mailbox-list.vertical
    .mail-list
    li
    .mxItemView.select:hover
    .mx-trash {
    background: var(--mxitem-view-select-bg-color);
  }
}

.position-relative {
  position: relative;
}

.dropdown-item.check-item:active {
  color: initial;
  text-decoration: initial;
  background-color: initial;
}

.dropdown-item.check-item input[type="checkbox"] {
  position: absolute;
  top: -2px;
}

.is-invalid .invalid-feedback {
  display: block;
}

.auto-cursor {
  cursor: auto !important;
}

.cursor-pointer {
  cursor: pointer;
}

.restricted {
  cursor: not-allowed;
}

.disabled {
  opacity: 0.5;
}

.nowrap {
  white-space: nowrap;
}

.underline {
  text-decoration: underline;
}

.underline:hover {
  text-decoration: underline;
}

.border-green {
  border: 1px solid green !important;
}

.rounded-checkbox {
  padding: 5px;
  border: 1px solid transparent;
  border-radius: 50%;
}

.rounded-checkbox--active {
  border: 1px solid var(--rounded-checkbox-border-color);
  background-color: var(--rounded-checkbox-bg-color);
}

.logo-big {
  width: 120px;
  height: 40px;
  object-fit: contain;
}

.border-grey {
  border: 1px solid #d1d1d1;

  border-radius: 3px;
}

.scheduled-date {
  border: 1px solid #dd4b39;
  background-color: #dd4b39;
  padding: 2px;
  border-radius: 2px;
  color: #fff;
}

.schedule-form .active {
  color: var(--btn-light-hover-text-color);
  background-color: var(--btn-light-hover-bg-color);
}

.modal-root {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1049;
  display: flex;
}

.modal-root:has(.external-tab) {
  display: flex;
  position: absolute;
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .modal-root {
    min-width: 100%;
  }
}

@media (max-width: 991.98px) {
  .search-header {
    padding: 0;
    width: 100%;
    float: none;
  }

  .search-header .mailbox-search .form-control {
    height: 48px;
    background: transparent;
    border: transparent;
  }

  .search-header .mailbox-search .form-control:focus {
    background: transparent;
  }

  .mailbox {
    height: auto;
  }

  .sidebar-app {
    display: none;
  }

  .sidebar-app.open {
    display: none;
  }

  .mailbox .mailbox-title-mobile {
    position: relative;
    top: auto;
    display: flex;
  }
}

/**
* Search madi queries
*/
@media (max-width: 767.98px) {
  .mailbox .mailbox-title-mobile .text-end {
    max-width: 100%;
  }

  .mailbox .mailbox-title-mobile > .col-12:not(.text-end) {
    display: none;
  }
}

/*
* Mobile
*/

@media (max-width: 992px) {
  body,
  html {
    overflow-y: auto;
    height: auto;
  }

  .mailbox {
    float: initial;
    height: auto;
  }

  .documents {
    height: calc(100vh - 162px);
  }

  .documents-layout {
    top: 0;
  }

  .mailbox .mailbox-detail {
    overflow-y: auto;
    height: auto;
  }

  .mailbox .mailbox-detail .mailbox-list {
    height: auto;
  }

  .mailbox-list .mail-list {
    height: auto;
  }

  .mobile-footer__placeholder {
    height: 102px;
  }

  .mailbox .mailbox-detail .mailbox-preview.group {
    height: auto;
  }
}

.w-144 {
  width: 144px !important;
}

.no-shadow {
  box-shadow: none;
}

.no-shadow:focus {
  box-shadow: none;
}

.w-160 {
  min-width: 160px;
  white-space: nowrap;
}

.setting-account {
  display: flex;
  flex-direction: row;
  height: 42px;
}

.setting-account-detail {
  position: relative;
  top: -20px;
  margin-left: 10px;
  height: 42px;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.newtab {
  width: 99%;
  /* height: 99%; */
  text-align: left;
  display: inline-block;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  pointer-events: auto;
  -webkit-transition: all 0.3s ease-out, width 0s;
  -o-transition: all 0.3s ease-out, width 0s;
  transition: all 0.3s ease-out, width 0s;
  vertical-align: bottom;

  overflow: hidden;

  border-radius: 4px;
  cursor: pointer;
  border: none;
}

.tox-textarea-wrap,
.tox-textarea {
  height: 100% !important;
}
