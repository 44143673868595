.account-item {
    display: flex !important;
    align-items: center;
    color: var(--primary-text-color) !important;
    text-align: left;
    font-weight: initial;
    cursor: pointer;
    background-color: var(--white-space-bg-color) !important;
    border-radius: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
}

.account-item a {
    color: var(--primary-text-color) !important;
}

.account-item span {
    margin-left: 0.5rem;
}

.account-item:hover {
    background: var(--mxitem-view-select-bg-color-hover) !important;
}

.account-item:last-child {
    border-bottom: 0px !important;
}

.add-button .pus-profile-photo div {
    color: var(--primary-bg-color) !important;
    background-color: var(--icon-btn-text-color);
}