.loading-bar {
  position: fixed;
  top: 0;
  width: auto;
  left: 50%;
  transform: translateX(-50%);
  color: #111111cc;
  padding: 3px 13px 5px;
  border-radius: 0 0 4px 4px;
  background-color: #ffeaa7;
  border: 1px solid #fdcb6e;
  border-top: 0;
  z-index: 9999;
}
