.attachments.simple {
  padding: 0;
}
.attachments.simple .download-all {
  display: none;
}
.attachments.simple .attachments-area {
  padding: 0;
  margin: 0;
}
.attachments.simple .attachments-area .file-attachment-item {
  margin: 0 5px 5px 0;
}
