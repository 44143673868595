body[data-theme="light"] {
  --primary-color: #216ff3;
  --primary-text-color: #001737;
  --primary-bg-color: #f2f4f4;
  --white-space-bg-color: #fff;
  --href-color: #007bff;
  --border-color: #dfe3e7;
  --table-text-color: inherit;
  --table-border-color: #dee2e6;
  --table2-th-bg-color: #fafafa;
  --checkbox-border-color: #aab7c2;
  --form-control-bg-color: #fff;
  --form-control-border-color: #ced4da;
  --form-control-text-color: #495057;
  --btn-border-color: transparent;
  --btn-hover-text-color: #fff;
  --btn-primary-bg-color: #0b5ed7;
  --btn-primary-text-color: #fff;
  --btn-primary-border-color: #0a58ca;
  --btn-light-bg-color: #f8f9fa;
  --btn-light-border-color: #f8f9fa;
  --btn-light-text-color: #212529;
  --btn-light-hover-bg-color: #e2e6dd;
  --btn-light-hover-border-color: #dae0e5;
  --btn-light-hover-text-color: #212529;
  --action-link-text-color: #9295a6;
  --action-link-hover-bg-color: #f3f3f3;
  --sidebar-header-bg-color: #22273c;
  --sidebar-header-border-color: #4a4e60;
  --sidebar-border-color: #4a4e60;
  --sidebar-bg-color: #24263d;
  --sidebar-text-color: #ffffff80;
  --sidebar-active-link-bg-color: #39395b;
  --sidebar-link-hover-bg-color: #2c314c;
  --sidebar-app-bg-color: #fff;
  --sidebar-app-link-text-color: inherit;
  --sidebar-app-link-hover-bg-color: #f3f3f3;
  --icon-btn-text-color: #5a5f64;
  --mxitem-view-bg-color: #fbfbfb;
  --mxitem-view-select-bg-color: #ecf1fcec;
  --mxitem-view-select-bg-color-unread: #e6eeff;
  --mxitem-view-select-bg-color-hover: #ecf1fcc0;
  --mxitem-view-action-checkbox-border-color: #dadee2;
  --mxitem-view-favorite-text-color: #dadee2;
  --empty-bg-color: #fbfbfb;
  --empty-text-color: #33333380;
  --mailgroup-title-text-color: #00173780;
  --attachment-inner-text-color: #231f20;
  --attachment-file-action-icons-bg-image: linear-gradient(
    90deg,
    #f0f0f000 1%,
    #f0f0f0 40%
  );
  --compose-title-bg-color: #24263d;
  --compose-body-bg-color: #fff;
  --compose-subject-after-bg-color: #dedede;
  --compose-attachment-bg-color: #f5f5f5;
  --custom-switch-bg-color: #e8e8e8;
  --custom-switch-border-color: #dbdbdb;
  --custom-switch-checked-bg-color: #007bff;
  --custom-switch-checked-border-color: #007bff;
  --custom-switch-checked-text-color: #fff;
  --custom-switch-checked-text-color: #fff;
  --statistics-item-title-bg-color: #f9fafa;
  --paginator-text-color: #231f2080;
  --pus-seperator-bg-color: #f1f1f1;
  --loading-overlay-bg-color: #fff;
  --loading-overlay-text-color: #6c757d;
  --ml-avatar-bg-color: #f1f1f1;
  --rounded-checkbox-bg-color: #d1d1d1;
  --rounded-checkbox-border-color: #c4c1c1;
  --pus-selected-row-bg-color: #f1f1f1;
  --ph-item-bg-color: #ddd;
  --ph-dark-item-bg-color: #9e9e9e;
  --react-select-multi-value-bg-color: #e6e6e6;
  --react-select-menu-bg-color: #fff;
  --react-select-focused-bg-color: #f1f1f1;
  --sign-list-item-hover-bg-color: #f4f4f4;
  --nav-tabs-link-bg-color: #efefef;
  --progress-bg-color: #e9ecef;
  --mobile-footer-active-link-bg-color: #1b1c2c;
  --mail-row-folder-name-bg-color: #f1f1f1;
  --datepicker-header-bg-color: #f0f0f0;
  --alert-primary-bg-color: #cce5ff;
  --alert-primary-border-color: #b8daff;
  --alert-primary-text-color: #004085;
  --alert-secondary-bg-color: #e2e3e5;
  --alert-secondary-border-color: #d6d8db;
  --alert-secondary-text-color: #383d41;
  --alert-success-bg-color: #d4edda;
  --alert-success-border-color: #c3e6cb;
  --alert-success-text-color: #155724;
  --alert-danger-bg-color: #f8d7da;
  --alert-danger-border-color: #f5c6cb;
  --alert-danger-text-color: #721c24;
  --alert-warning-bg-color: #fff3cd;
  --alert-warning-border-color: #ffeeba;
  --alert-warning-text-color: #856404;
  --alert-info-bg-color: #d1ecf1;
  --alert-info-border-color: #bee5eb;
  --alert-info-text-color: #0c5460;
  --alert-light-bg-color: #fefefe;
  --alert-light-border-color: #fdfdfe;
  --alert-light-text-color: #818182;
  --alert-dark-bg-color: #d6d8d9;
  --alert-dark-border-color: #c6c8ca;
  --alert-dark-text-color: #1b1e21;
  --calendar-today-bg-color: #d3e2fd8a;
  --calendar-toolbar-btn-bg-color: #fff;
  --calendar-toolbar-btn-text-color: #212529;
  --calendar-toolbar-btn-border-color: #cdcdcd;
  --calendar-toolbar-btn-hover-bg-color: #ececec;
  --calendar-toolbar-btn-hover-text-color: #212529;
  --snackbar-bg-color: #212121;
  --snackbar-error-bg-color: #990000;
  --user-panel-login: #ffd200;
  --sideSvgColor: #fff;
  --icon-filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg)
    brightness(100%) contrast(90%);
}

body[data-theme="dark"] {
  --primary-color: #36a1f2;
  --primary-text-color: #fff;
  --primary-bg-color: #273341;
  --white-space-bg-color: #15202c;
  --href-color: #1b95e0;
  --border-color: #38444c;
  --table-text-color: #fff !important;
  --table-border-color: #38444c !important;
  --table2-th-bg-color: #273341 !important;
  --checkbox-border-color: #38444c;
  --form-control-bg-color: #273341;
  --form-control-border-color: #38444c;
  --form-control-text-color: #ffffff;
  --btn-border-color: #38444c;
  --btn-hover-text-color: #fff;
  --btn-primary-bg-color: #0b5ed7;
  --btn-primary-text-color: #fff;
  --btn-primary-border-color: #0a58ca;
  --btn-light-bg-color: #273341;
  --btn-light-border-color: #38444c;
  --btn-light-text-color: #8899a6;
  --btn-light-hover-bg-color: #273341;
  --btn-light-hover-border-color: #273341;
  --btn-light-hover-text-color: #fff;
  --action-link-text-color: #8899a6;
  --action-link-hover-bg-color: #12293a;
  --sidebar-header-bg-color: #15202c;
  --sidebar-header-border-color: #38444c;
  --sidebar-border-color: #38444c;
  --sidebar-bg-color: #15202c;
  --sidebar-text-color: #ffffff80;
  --sidebar-active-link-bg-color: #273341;
  --sidebar-link-hover-bg-color: #12293a;
  --sidebar-app-bg-color: #15202c;
  --sidebar-app-link-text-color: #8899a6;
  --sidebar-app-link-hover-bg-color: #12293a;
  --icon-btn-text-color: #fff;
  --mxitem-view-bg-color: #15202c;
  --mxitem-view-select-bg-color: #1e2c3ebe;
  --mxitem-view-select-bg-color-unread: #1b2738;
  --mxitem-view-select-bg-color-hover: #1e2c3e8f;
  --mxitem-view-action-checkbox-border-color: #38444b;
  --mxitem-view-favorite-text-color: #38444b;
  --empty-bg-color: #15202c;
  --empty-text-color: #8899a6;
  --mailgroup-title-text-color: #8899a6;
  --attachment-inner-text-color: #7b8793;
  --attachment-file-action-icons-bg-image: linear-gradient(
    90deg,
    #f0f0f000 1%,
    #2f3a45 40%
  );
  --compose-title-bg-color: #1e2c3f;
  --compose-body-bg-color: #1e2c3f;
  --compose-subject-after-bg-color: #38444b;
  --compose-attachment-bg-color: #273341;
  --custom-switch-bg-color: #273341;
  --custom-switch-border-color: #38444c;
  --custom-switch-checked-bg-color: #36a1f2;
  --custom-switch-checked-border-color: #36a1f2;
  --custom-switch-checked-text-color: #fff;
  --statistics-item-title-bg-color: #15202c;
  --paginator-text-color: #8899a6;
  --pus-seperator-bg-color: #273341;
  --loading-overlay-bg-color: #15202c;
  --loading-overlay-text-color: #ffffff90;
  --ml-avatar-bg-color: #273341;
  --rounded-checkbox-bg-color: #38444c;
  --rounded-checkbox-border-color: #38444c;
  --pus-selected-row-bg-color: #273341;
  --ph-item-bg-color: rgba(255, 255, 255, 0.1);
  --ph-dark-item-bg-color: rgba(255, 255, 255, 0.1);
  --react-select-multi-value-bg-color: #273341;
  --react-select-menu-bg-color: #273341;
  --react-select-focused-bg-color: #38444c;
  --sign-list-item-hover-bg-color: #1e2c3f;
  --nav-tabs-link-bg-color: #38444c;
  --progress-bg-color: #273341;
  --mobile-footer-active-link-bg-color: #1e2c3f;
  --mail-row-folder-name-bg-color: #38444c;
  --datepicker-header-bg-color: #1e2c3f;
  --alert-primary-bg-color: #cce5ff25;
  --alert-primary-border-color: #b8daff25;
  --alert-primary-text-color: #b8daff;
  --alert-secondary-bg-color: #e2e3e525;
  --alert-secondary-border-color: #d6d8db25;
  --alert-secondary-text-color: #d6d8db;
  --alert-success-bg-color: #d4edda25;
  --alert-success-border-color: #c3e6cb25;
  --alert-success-text-color: #c3e6cb;
  --alert-danger-bg-color: #ff637225;
  --alert-danger-border-color: #f5c6cb30;
  --alert-danger-text-color: #ff6372;
  --alert-warning-bg-color: #ffeeba30;
  --alert-warning-border-color: #ffeeba30;
  --alert-warning-text-color: #ffeeba;
  --alert-info-bg-color: #d1ecf125;
  --alert-info-border-color: #bee5eb25;
  --alert-info-text-color: #bee5eb;
  --alert-light-bg-color: #fefefe25;
  --alert-light-border-color: #fdfdfe25;
  --alert-light-text-color: #fdfdfe;
  --alert-dark-bg-color: #d6d8d925;
  --alert-dark-border-color: #c6c8ca25;
  --alert-dark-text-color: #c6c8ca;
  --calendar-today-bg-color: #1830548a;
  --calendar-toolbar-btn-bg-color: #273341;
  --calendar-toolbar-btn-text-color: #8899a6;
  --calendar-toolbar-btn-border-color: #38444c;
  --calendar-toolbar-btn-hover-bg-color: #12293a;
  --calendar-toolbar-btn-hover-text-color: #fff;
  --snackbar-bg-color: #2a3d52;
  --snackbar-error-bg-color: #990000;
  --user-panel-login: #d4ae00;
  --sideSvgColor: #fff;
  --icon-filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg)
    brightness(100%) contrast(100%);
}
