.btn-user-panel-login {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  background-color: var(--user-panel-login) !important;
  font-weight: 500 !important;
  font-size: 13px !important;
  line-height: 22px !important;
  letter-spacing: 0.46px !important;
  color: var(--primary-text-color) !important;
}

.btn-user-panel-login.open {
  position: absolute;
  right: 27rem;
}

.btn-close-icon {
  text-align: left;
  width: min-content;
}

.btn-close-icon:hover {
  cursor: pointer;
}

.login-bg {
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
}

.login-main {
  overflow: auto;
}

.login-body {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  font-family: sans-serif;
  -webkit-app-region: drag;
  height: 100vh;
}
.login-body .form-items {
  border-radius: 4px;
  padding: 40px;
  padding-bottom: 0px;
  background-color: var(--white-space-bg-color);
  -webkit-box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
  display: -ms-flexbox;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-app-region: no-drag;
  max-width: 440px;
  width: 100%;
  z-index: 1;
}
.form-footer {
  padding: 0 40px;
  max-width: 440px;
  width: 100%;
}
.login-body .logo {
  position: relative;
  z-index: 1;
  display: inherit;
  -ms-flex-align: inherit;
  align-items: inherit;
  border-radius: inherit;
  margin: 15px 0 15px;
}
.login-body .logo a {
  display: block;
  width: 100%;
}
.login-body .logo a > img {
  height: 60px;
}
.login-body .form-items h3 {
  font-size: 18px;
  margin: 0 0 20px;
  font-weight: 600;
}
.login-body .form-items .form-group {
  margin-bottom: 15px;
}
.login-body .form-items .form-group label {
  margin-bottom: 2px;
}
.login-body .form-items .form-group + .form-group {
  margin-top: 10px;
}
.login-body .form-items .form-control {
  height: 44px !important;
}

.login-body .form-items .btn {
  height: 44px;
  margin-top: 0 !important;
  margin-bottom: 15px !important;
  font-size: 15px;
  font-weight: 600;
}

.login-body .loginBackground {
  min-height: 692px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;
  overflow: hidden;
  padding-top: 64px;
  background-color: var(--primary-bg-color);
}
.login-body .loginBackground .gridContainer {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: [start] 1fr [left-gutter] (86.6px) [16] [left-gutter] 1fr
    [end];
  grid-template-columns: [start] 1fr [left-gutter] repeat(16, 86.6px) [left-gutter] 1fr [end];
  -ms-grid-rows: [top] 1fr [top-gutter] (64px) [8] [bottom-gutter] 1fr [bottom];
  grid-template-rows: [top] 1fr [top-gutter] repeat(8, 64px) [bottom-gutter] 1fr [bottom];
  justify-content: center;
  margin: 0 -2%;
  transform: rotate(-12deg) skew(-12deg);
}
.login-body .loginBackground .gridContainer .grid-box {
  display: -ms-flexbox;
  display: flex;
}
.login-body .box-divider {
  box-shadow: inset 0 0 0 2px var(--sidebar-app-link-hover-bg-color);
}
.login-body .bg1 {
  background-color: #212d63;
}
.login-body .bg2 {
  background-color: var(--btn-primary-border-color);
}
.login-body .bg3 {
  background-color: var(--white-space-bg-color);
}
.login-body .bg4 {
  background-color: var(--href-color);
}
.login-body .bg5 {
  background-color: var(--white-space-bg-color);
}

.login-body .login-footer {
  text-align: center;
  word-break: break-word;
  top: 20px;
  margin: 10px 0;
  position: relative;
  line-height: 22px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
}
.login-body .login-footer > a {
  margin-left: 12px;
  margin-right: 12px;
  white-space: nowrap;
  color: #2170f4;
  text-decoration: none;
}
.login-body .login-footer > a.active {
  color: #888;
  font-weight: 600;
}

.login-content {
  position: relative;
  width: 100%;
  max-width: 440px;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 1;
}
.login-content--left,
.login-content--right {
  background-color: rgba(255, 255, 255, 0.85);
  min-height: 100vh;
  height: 100%;
  padding: 20px 0;
  overflow: auto;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
}
.login-content--left {
  align-self: flex-start;
}
.login-content--right {
  align-self: flex-end;
}
.login-content--left .form-items,
.login-content--right .form-items {
  background-color: transparent;
  box-shadow: none;
}

@media only screen and (max-width: 767px) {
  .login-body .login-body {
    background: #fff;
  }
  .login-body .loginBackground {
    display: none;
  }
  .login-body .form-items {
    box-shadow: none;
    padding: 25px;
  }
  .login-body .form-items h3 {
    text-align: center;
  }
  .login-body .forgot {
    display: block;
    text-align: center;
  }
  .login-content--left,
  .login-content--right {
    padding-top: 20px;
  }
}

@media only screen and (max-width: 320px) {
  .login-body .login-footer {
    top: 20px;
  }
}

.custom-tab1 {
  margin-bottom: 20px;
}
.custom-tab1 .nav-tabs {
  border-bottom: 2px solid #c8d7de;
}
.custom-tab1 .nav-link {
  position: relative;
  font-size: 14px;
  width: 100%;
  font-weight: 800;
  color: #2d373c;
}

.custom-tab1 .nav-tabs .nav-item {
  flex-grow: 1;
}
.custom-tab1 .nav-tabs .nav-item button {
  background: transparent;
}
.custom-tab1 .nav-tabs .nav-item.show .nav-link,
.custom-tab1 .nav-tabs .nav-link.active {
  color: #007bff;
  border: 0;
  background: #0000;
}
.custom-tab1 .nav-tabs .nav-link.active:before {
  position: absolute;
  content: "";
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 2px;
  background: #007bff;
}
.custom-tab1 .nav-tabs .nav-link {
  text-align: center;
  line-height: 1.2;
  font-size: 15px;
  color: #8a959a;
}
.custom-tab1 .nav-tabs .nav-link small {
  display: block;
  font-size: 15px;
}
.custom-tab1 .tab-content {
  padding: 50px 0;
  line-height: 1.5;
  color: #5a5a5a;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 400;
}
.custom-tab1 .tab-content p {
  line-height: 1.5;
  color: #5a5a5a;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 400;
}
.custom-tab1 .tab-content p:last-child {
  margin-bottom: 0;
}

.custom-tab1 button:focus {
  border: 0;
  outline: none !important;
}
.custom-tab1 button:hover {
  border: transparent !important;
  outline: none !important;
  margin-bottom: 0px;
  margin-top: 1px;
}
.custom-tab1 button.active:hover {
  margin-top: 0;
}

.bottom-version {
  margin-top: 10px;
  z-index: 1;
}

.login-sidebar {
  position: absolute;
  background-color: var(--white-space-bg-color);
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.08);
  top: 0;
  right: 0;
  width: 360px;
  height: 100vh;
  z-index: 99999;
  display: none;
}

.open {
  display: block;
}

.login-sidebar-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 42.5px);
  padding: 20px;
}

.login-sidebar-container > div > div {
  width: 100%;
}
