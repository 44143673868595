.draft-blur {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 60px;
  background-color: var(--loading-overlay-bg-color);
  z-index: 2;
  opacity: 0.5;
}
.cr-pointer {
  cursor: pointer;
}

.pus-collapse {
  position: absolute;
  right: 18px;
  top: 5px;
  border-radius: 2px;
}
.pus-collapse .btn {
  border: none;
}

.pus-collapse:hover {
  background: var(--action-link-hover-bg-color);
}

.pus-external {
  position: absolute;
  right: 105px;
  top: 4px;
}

.pus-external:hover {
  background: var(--action-link-hover-bg-color);
}

.pus-reminder {
  position: absolute;
  right: 146px;
  top: 4px;
}

.pus-reminder:hover {
  background: var(--action-link-hover-bg-color);
}

.pus-reminder-ui {
  right: 85px;
}

.ntimes {
  padding: 10px;
  text-align: center;
  font-weight: bold;
  margin: 5px 0;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .pus-reminder {
    right: 85px;
  }
  .pus-reminder-ui {
    right: 50px;
  }
  .pus-external {
    right: 55px;
  }
}
