.pus-checkbox-container {
  cursor: pointer;
}
.pus-checkbox {
  align-content: center;
  border-style: solid;
  border-width: 1px;
  margin: 0;
  margin-right: 5px;
  border-color: var(--checkbox-border-color);
  background: var(--white-space-bg-color);
  color: #3dbfce;
  clear: none;
  display: inline-block;
  line-height: 1em;
  height: 16px;
  outline: 0;
  padding: 0;
  text-align: center;
  border-radius: 3px;
  vertical-align: middle;
  width: 16px;
  min-width: 16px;
  -webkit-appearance: none;
  transition: 0.05s border-color ease-in-out;
  box-shadow: none;
}

.pus-checkbox.checked:before {
  display: inline-block;
  speak: none;
  line-height: 16px;
  content: "\2714\fe0e";
  width: 16px;
  height: 16px;
  margin: -2px;
  font-size: 14px;
  background: #596576;
  border-radius: 2px;
  color: #fff;
}

.pus-checkbox.anyone:before {
  display: inline-block;
  speak: none;
  line-height: 16px;
  content: "-";
  width: 16px;
  height: 16px;
  margin: -2px;
  font-size: 14px;
  background: #596576;
  border-radius: 2px;
  color: #fff;
}
