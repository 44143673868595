.btn-user-panel-login {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  background-color: var(--user-panel-login) !important;
  font-weight: 500 !important;
  font-size: 13px !important;
  line-height: 22px !important;
  letter-spacing: 0.46px !important;
  color: var(--primary-text-color) !important;
}

.btn-user-panel-login.open {
  position: absolute;
  right: 27rem;
}

.btn-close-icon {
  text-align: left;
  width: min-content;
}

.btn-close-icon:hover {
  cursor: pointer;
}

.login-sidebar-container h3 {
  font-size: 18px;
  margin: 0 0 20px;
  font-weight: 600 !important;
}
.login-sidebar-container .form-group {
  margin-bottom: 15px;
}
.login-sidebar-container .form-group label {
  margin-bottom: 2px;
}
.login-sidebar-container .form-group + .form-group {
  margin-top: 10px;
}
.login-sidebar-container .form-control {
  height: 44px !important;
}

.login-sidebar-container .btn {
  height: 44px;
  margin-top: 0 !important;
  margin-bottom: 15px !important;
  font-size: 15px;
  font-weight: 600;
  border: none;
}

.custom-tab1 {
  margin-bottom: 20px;
}
.custom-tab1 .nav-tabs {
  border-bottom: 2px solid #c8d7de;
}
.custom-tab1 .nav-link {
  position: relative;
  font-size: 14px;
  width: 100%;
  font-weight: 800;
  color: #2d373c;
}

.custom-tab1 .nav-tabs .nav-item {
  flex-grow: 1;
}
.custom-tab1 .nav-tabs .nav-item button {
  background: transparent;
}
.custom-tab1 .nav-tabs .nav-item.show .nav-link,
.custom-tab1 .nav-tabs .nav-link.active {
  color: #007bff;
  border: 0;
  background: #0000;
}
.custom-tab1 .nav-tabs .nav-link.active:before {
  position: absolute;
  content: "";
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 2px;
  background: #007bff;
}
.custom-tab1 .nav-tabs .nav-link {
  text-align: center;
  line-height: 1.2;
  font-size: 15px;
  color: #8a959a;
}
.custom-tab1 .nav-tabs .nav-link small {
  display: block;
  font-size: 15px;
}
.custom-tab1 .tab-content {
  padding: 50px 0;
  line-height: 1.5;
  color: #5a5a5a;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 400;
}
.custom-tab1 .tab-content p {
  line-height: 1.5;
  color: #5a5a5a;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 400;
}
.custom-tab1 .tab-content p:last-child {
  margin-bottom: 0;
}

.custom-tab1 button:focus {
  border: 0;
  outline: none !important;
}
.custom-tab1 button:hover {
  border: transparent !important;
  outline: none !important;
  margin-bottom: 0px;
  margin-top: 1px;
}
.custom-tab1 button.active:hover {
  margin-top: 0;
}

.login-sidebar {
  position: absolute;
  background-color: var(--white-space-bg-color);
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.08);
  top: 0;
  right: 0;
  width: 360px;
  height: 100vh;
  z-index: 99999;
  display: none;
}

.open {
  display: block;
}

.login-sidebar-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 42.5px);
  padding: 20px;
}

.login-sidebar-container > div {
  width: 100%;
}
