.select__container {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
  background: var(--compose-body-bg-color);
  padding: 5px 0;
  width: 280px;
}

.select__focused:hover {
  background-color: var(--react-select-focused-bg-color);
  border-radius: 3px;
}

.select__selected {
  background-color: var(--react-select-focused-bg-color);
  border-radius: 3px;
}

.select__avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 36px;
  min-width: 36px;
  height: 36px;
  background-color: #d1d1d1;
  margin: 0 10px;
  border-radius: 50%;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
}

.select__area {
  position: relative;
  max-width: calc(100% - 60px);
  min-width: 100px;
}

.select__label {
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.select__value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.select__dlist {
  background-color: var(--react-select-focused-bg-color);
}

.select__expand {
  margin-left: auto;
  margin-right: 5px;
}

.select__expand-button {
  border-radius: 3px;
  border: 1px solid var(--border-color);
  padding: 0px 4.5px;
  background: var(--compose-body-bg-color);
  color: var(--form-control-text-color);
}

.compose-header .compose-participant-field .select__value span,
.compose-header .compose-participant-field .select__label span {
  position: relative;
  text-transform: none;
  display: inline;
  top: 0;
  left: 0;
  padding: 0;
  cursor: default;
}

.select__highlight {
  background-color: inherit;
  font-weight: bold;
  padding: 0;
  color: var(--primary-text-color);
}

.select__multi-value {
  overflow: hidden;
  text-overflow: ellipsis;
}
