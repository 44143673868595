.snackbars {
  position: fixed;
  bottom: 10px;
  z-index: 99999;
}

.snackbars__content {
  display: flex;
  justify-content: space-between;
  color: #fff;
  padding: 20px 15px;
  margin: 10px;
  width: 360px;
  border-radius: 5px;
  background-color: var(--snackbar-bg-color);
}

.snackbars_error__content {
  display: flex;
  justify-content: space-between;
  color: #fff;
  padding: 20px 15px;
  margin: 10px;
  width: 360px;
  border-radius: 5px;
  background-color: var(--snackbar-error-bg-color);
}

.snackbars__action {
  line-height: 30px;
  padding: 0 10px;
  cursor: pointer;
  color: #70a1ff;
  text-transform: capitalize;
  border-radius: 4px;

  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
}
.snackbars__action:hover {
  background-color: #70a1ff35;
}
.snackbars__close {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;

  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
}
.snackbars__close:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.snackbars__close:after {
  color: #f1f1f1;
  font-size: 24px;
  content: "\00d7";
}

@media only screen and (max-width: 420px) {
  .snackbars__content {
    width: 300px;
  }
}
