.pus-printer-body .mail-content .mail-content-header .ch-tool {
  display: none;
}

.pus-printer-body .mail-content .mail-content-footer {
  display: none;
}

.pus-printer-content {
  float: right;
  margin-top: -10px;
  margin-right: 60px;
  min-height: 20px;
  margin-left: 50px;
}
.pus-printer-btn {
  font-size: 18px;
  padding-bottom: 2px;
  padding-top: 2px;
}
.bg-calendar {
  background: var(--primary-bg-color);
}

@media only screen and (max-width: 767px) {
  .pus-printer-content {
    margin-right: 70px;
  }
}
