.password-hint {
  background-color: var(--primary-bg-color);
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 10px;
}

.password-hint__item {
  display: flex;
}

.password-hint__valid {
  color: green;
  margin-right: 10px;
  margin-left: 10px;
}

.password-hint__invalid {
  color: tomato;
  margin-right: 10px;
  margin-left: 10px;
}
