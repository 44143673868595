.list-icon {
  position: absolute;
  left: 20px;
  top: 7px;
  width: 22px;
  font-size: 18px;
  line-height: 1;
}

.icon-left {
  left: 18px !important;
}

.icon-top {
  top: 4px !important;
}

.add-folder {
  position: relative;
  width: 100%;
  text-transform: capitalize;
  display: block;
  font-size: 11px;
  padding: 6px 20px 7px 46px;
  padding-left: 42px;
  margin: 5px 0;
  color: #9295a6;
  background: transparent;
  border: 0;
  outline: 0 !important;
  text-align: left;
}

.add-folder:hover {
  background-color: var(--sidebar-link-hover-bg-color);
}

.add-folder .list-icon {
  top: 5px;
  font-size: 17px;
}

.sidebar-main .mail-menu ul li a {
  display: flex !important;
  justify-content: space-between;
}
.sidebar-main .mail-menu ul li a > span,
a.mw-mobile-nav__item > span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 10px;
  text-transform: none;
}

.sidebar-main .mail-menu ul li a.sub-folder:before {
  left: 6px;
}

.sub-folder-button {
  position: absolute;
  left: 0;
  top: 0;
  height: 42px;
  max-height: 100%;
  width: 20px;
  opacity: 0;
}

.mailbox .mailbox-title ul.mail-action1 li .btn-icon svg {
  width: 21px;
  line-height: 21px;
  text-align: center;
  font-size: 16px;
}

.mailbox-list .mail-list,
.empty-mail {
  overflow-y: auto;
}
.mailbox-list .mail-list li .mxItemView {
  z-index: auto;
}
.mailbox-list .mail-list li .mxItemView .mx-action .favorite svg {
  position: relative;
  color: var(--mxitem-view-favorite-text-color);
}
.mailbox-list .mail-list li .mxItemView .mx-action .favorite.open svg {
  position: relative;
  color: #fdac41;
}
.mailbox-list .mail-list li .mxItemView .mx-trash .btn-light svg {
  width: 18px;
  line-height: 18px;
  font-size: 15px;
  text-align: center;
}
.mailbox-list .mail-list li .mxItemView .mx-action .tracking {
  z-index: 1;
}

.mail-content .mail-content-header .ch-name .ml-title p.custom-info-line span {
  top: -4px;
}
.mail-content
  .mail-content-header
  .ch-name
  .ml-title
  p.custom-info-line
  span
  svg {
  font-size: 12px;
  color: #828d99;
}

.mail-content .mail-content-header .custom-ch-tool .btn-icon svg {
  width: 20px;
  line-height: 20px;
  text-align: center;
  font-size: 16px;
}

.custom-reply-dropdown svg {
  position: absolute;
  left: 10px;
  top: 10px;
  font-size: 15px;
}

.mw-mobile-menu .mw-mobile-nav__item.active {
  color: var(--primary-color);
}
.mw-mobile-menu .mw-mobile-nav__item > svg {
  position: relative;
  top: 1px;
  font-size: 18px;
  margin-right: 10px;
  left: 0;
}

.mw-mobile-menu .mw-mobile-nav__item > svg.icon-left {
  left: -2px !important;
}

.mw-mobile-menu .mw-mobile-nav__item > img {
  position: relative;
  top: 1px;
  font-size: 18px;
  margin-right: 10px;
  left: -2px !important;
}

body[data-theme="light"]
  .mw-mobile-menu
  .mw-mobile-nav__item
  > img.filter-color {
  filter: invert(1);
}

.mw-mobile-nav__item.sub-folder::before {
  position: absolute;
  content: "";
  left: -12px;
  top: 16px;
  display: inline-block;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transition: transform 0.3s;
  transform: scale(0.75) rotate(45deg);
  width: 8px;
  height: 8px;
  color: #a4a4a7;
}
.mw-mobile-nav__item.sub-folder.open::before {
  transform: translateY(4px) scale(0.75) rotate(-135deg);
}
.mw-mobile-nav__item ~ ul.tree {
  padding-left: 15px;
  display: none;
}
.mw-mobile-nav__item ~ ul.tree li {
  border-bottom: 0;
}

.pus-mobile-visible {
  display: block !important;
}
.folder-more .action-btn {
  padding: 0 10px;
  margin-right: -10px;
  border: 0;
  background: transparent;
  color: #ffffff;
  outline: none !important;
}

.sidebar-main .mail-menu ul li a .folder-more .action-btn {
  display: none;
}
.sidebar-main .mail-menu ul li a:hover .folder-more .action-btn,
.folder-more .action-btn:active,
.folder-more .action-btn:focus {
  display: block !important;
}

.mw-mobile-menu .cat-count {
  right: 17px;
}
.mw-mobile-nav__item .folder-more {
  position: absolute;
  right: 0;
  top: 10px;
  bottom: 0;
}
.mw-mobile-nav__item .folder-more .action-btn {
  color: var(--primary-text-color);
}

.custom-folder-tree {
  height: 333px;
  overflow: auto;
}
.custom-folder-tree ul {
  margin: 0;
  padding: 0;
  list-style: none;
  /* padding-left: 15px; */
}
.custom-folder-tree ul:first-child {
  padding-left: 0;
}

.pus-move-arrow {
  cursor: col-resize;
}

.pus-move-arrow.pus-move-arrow-row {
  cursor: row-resize;
}

.pus-move-arrow > * {
  pointer-events: none;
}

.pus-parent__vertical {
  display: flex;
}

.pus-parent__horizontal {
  display: flex;
  flex-direction: column;
}

@media (min-width: 992px) {
  .mailbox .pus-parent__horizontal {
    height: calc(100vh - 113px);
    overflow: auto;
  }

  .mailbox-list.horizontal {
    overflow: auto;
  }
}

.pus-parent__default .pus-seperator {
  display: none;
}

.pus-parent__horizontal .pus-seperator {
  width: 100%;
  height: 4px;
  cursor: row-resize;
}

.pus-seperator {
  width: 4px;
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  height: 100%;
  border: 2px solid var(--pus-seperator-bg-color);
  cursor: col-resize;
  z-index: 3;
}

.folder-popper-icon {
  width: 28px;
  text-overflow: unset !important;
}

.sidebar-main .mail-menu .nav-link__clear-margin {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.field-input {
  border: 1px solid var(--border-color);
  background: var(--compose-body-bg-color);
  color: var(--form-control-text-color);
}

.composer-options {
  position: absolute;
  z-index: 10;
  border-radius: 5px;
  border: 1px solid var(--border-color);
  background: var(--compose-body-bg-color);
  overflow: auto;
  max-height: 400px;
}

.composer-dlist {
  position: absolute;
  z-index: 11;
  border-radius: 5px;
  border: 1px solid var(--border-color);
  background: var(--compose-body-bg-color);
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 400px;
  min-width: 100px;
  max-width: 300px;
}
