.search-btn {
  position: absolute;
  left: 6px;
  top: 6px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: transparent;
  color: var(--primary-text-color);
}

.advanced-btn {
  position: absolute;
  right: 6px;
  top: 6px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: transparent;
  color: var(--primary-text-color);
}

.clear-btn {
  position: absolute;
  right: 32px;
  top: 6px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: transparent;
  color: var(--primary-text-color);
}

button.dropdown-user-link {
  padding: 14px 18px;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #9295a6;
  margin: 15px 4px 0;
  border-radius: 4px;
  background: transparent;
  border: 0;
  outline: 0 !important;
}

button.dropdown-user-link:hover {
  background: var(--action-link-hover-bg-color);
}

.custom-dropdown-menu {
  margin: 4px -8px;
  min-width: 9rem;
}

.custom-dropdown-menu .dropdown-item {
  position: relative;
  display: block;
  width: 100%;
  padding: 8px 15px;
  font-size: 13px;
  clear: both;
  text-align: inherit;
  border: 0;
  min-width: 9rem;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--primary-text-color);
}

.custom-dropdown-menu .px-item {
  padding: 8px 8px !important;
}

.custom-dropdown-menu .dropdown-item:focus,
.custom-dropdown-menu .dropdown-item:hover {
  color: var(--primary-text-color);
  text-decoration: none;
  background-color: var(--action-link-hover-bg-color);
  outline: 0;
}

.custom-dropdown-menu .dropdown-item svg {
  margin-right: 10px;
}

.dropdown-divider {
  border-color: var(--border-color) !important;
}

.my-tippy-tooltip[data-placement^="top"] > .my-tippy-arrow {
  bottom: -6px;
}

.my-tippy-tooltip[data-placement^="top"] > .my-tippy-arrow::before {
  border-top: none;
  border-bottom: 1px solid var(--border-color);
  border-left: none;
  border-right: 1px solid var(--border-color);
}

.my-tippy-tooltip[data-placement^="right"] > .my-tippy-arrow {
  left: -6px;
}

.my-tippy-tooltip[data-placement^="right"] > .my-tippy-arrow::before {
  border-top: none;
  border-left: 1px solid var(--border-color);
  border-right: none;
  border-bottom: 1px solid var(--border-color);
}

.my-tippy-tooltip[data-placement^="bottom"] > .my-tippy-arrow {
  top: -6px;
}

.my-tippy-arrow,
.my-tippy-arrow::before {
  width: 12px;
  height: 12px;
}

.mail-group-title:first-child {
  margin-top: 22px;
}

.mobile-header button {
  width: 40px;
  height: 40px;
  line-height: 38px;
  text-align: center;
  font-size: 26px;
  color: #fff;
  border-radius: 50%;
}

.mobile-header button:hover,
.mobile-header button:active,
.mobile-header button:focus {
  background: #00000040;
  color: #fff;
}

.mobile-header button.bars {
  border: none;
}

.mobile-header button.search {
  margin-left: 40px;
  border: none;
}

.mobileSearch button {
  width: auto;
  top: 0;
  right: auto;
  position: relative;
  margin: 0 7px;
}

.search-header .mailbox-search .form-control {
  padding-left: 0.75rem;
}

.search-header .mailbox-search .form-control:focus {
  background-color: inherit;
}

.action-header ul.nav li button.nav-link {
  color: var(--action-link-text-color);
  padding: 5.5px 6px 4px;
  margin: 14px 3px 0;
  border-radius: 4px;
  border: none;
}

.action-header ul.nav li button.nav-link:hover {
  background: var(--action-link-hover-bg-color);
}

.mobile-footer button {
  float: left;
  width: 20%;
  padding: 5px 0;
  display: block;
  color: #fff;
  line-height: 1;
  text-align: center;
  border: 0;
  border-right: 1px solid #ffffff12;
}

.mobile-footer button:hover {
  color: #fff;
  text-decoration: underline;
}

.mobile-footer button i {
  display: block;
  font-size: 32px;
  margin: 0 auto;
}

.mobile-footer button span {
  display: block;
  font-size: 11px;
}

.mobile-footer a.dropdown-item {
  float: initial;
  width: 100%;
  padding: 8px 15px;
  display: block;
  line-height: initial;
  text-align: inherit;
  border-right: initial;
}

.mobile-footer a.dropdown-item:last-child {
  border-right: initial;
}

.mobile-footer a.dropdown-item i {
  display: initial;
  font-size: initial;
  margin: initial;
}

.mobile-footer a.dropdown-item span {
  display: initial;
  font-size: inherit;
}

.mobile-footer a.dropdown-item.active {
  color: initial;
  background: initial;
}

.pus-center {
  text-align: center;
}

.pus-info {
  padding: 5px;
}

.pus-mail-truncate {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pus-profile-photo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pus-profile-photo img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.pus-profile-photo div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  min-width: 30px;
  color: #fff;
  font-size: 1.5em;
  text-transform: uppercase;
  border-radius: 50%;
  background-color: tomato;
}

.pus-profile-letter__medium {
  font-size: 1.85em !important;
}

.pus-profile-letter__big {
  font-size: 3em !important;
}

.pus-profile-letter__x_large {
  font-size: 5em !important;
}

.pus-has-image div {
  background: transparent;
  border: 2px solid #f1f1f1;
}

.pus-profile-photo__small div {
  height: 30px;
  width: 30px;
  min-width: 30px;
  font-size: 1.5em !important;
}

.pus-profile-photo__big div {
  height: 70px;
  width: 70px;
  min-width: 70px;
  font-size: 3em;
}

.pus-profile-photo__medium div {
  height: 40px;
  width: 40px;
  min-width: 40px;
  font-size: 1.85em;
}

.pus-profile-photo__large div {
  height: 96px;
  width: 96px;
  min-width: 40px;
  font-size: 1.85em;
}

.pus-profile-photo__x_large div {
  height: 156px;
  width: 156px;
  min-width: 40px;
  font-size: 2em;
}

.pus-photo-container {
  margin: 10px auto;

  position: relative;
  height: 75px;
  width: 75px;
}

.pus-change-photo-container {
  border: 2px solid #fff;
  border-radius: 50%;
  position: absolute;
  bottom: -3px;
  right: -3px;
  cursor: pointer;
}

.pus-change-photo {
  width: 26px;
  height: 26px;
  box-shadow: 0 1px 1px 0 rgba(65, 69, 73, 0.3),
    0 1px 3px 1px rgba(65, 69, 73, 0.15);
  background-color: #f1f1f1;
  border-radius: 50%;
  padding: 3px 5px;
}

.pus-change-photo:hover {
  color: #16181b;
}

.pus-change-photo__invisible {
  position: absolute;
  left: 0;
  top: 0;
  width: 32.5px;
  height: 32.5px;
  opacity: 0;
}

.sidebar.minimum .tree {
  padding-left: 0;
}

.mw-mobile-menu__container .mail-compose .btn i {
  display: none;
}

.sidebar-main {
  overflow: auto !important;
}

.mw-mobile-menu__container .sidebar-main {
  padding-bottom: 120px !important;
}

/* TABLE */

.log-list .heading {
  position: sticky;
  left: 0;
  top: 0;
  right: 0;
  z-index: 2;
  height: 60px;
}

.log-list .heading > div {
  height: 100%;
}

.log-list .heading > div > div {
  display: flex;
}

.log-list .heading > div > div.status {
  justify-content: center;
}

.log-list .heading > div > div.date {
  justify-content: flex-end;
}

.log-list .heading > div > div > button {
  display: flex;
  align-items: center;
  color: inherit;
  font: inherit;
  border: none;
  padding: 0;
  outline: none;
  background-color: transparent;
}

.log-list .heading > div > div > button svg {
  margin-right: 7px;
}

.log-list .cxItem .cxItemView.deleted {
  position: relative;
  opacity: 0.25;
  cursor: default !important;
  background: #fff !important;
}

.log-list .cxItem .cxItemView .action {
  padding-left: 70px !important;
  background-color: var(--primary-bg-color);
}

.log-list .cxItem .cxItemView.deleted .check,
.log-list .cxItem .cxItemView.deleted .action {
  visibility: hidden !important;
}

.log-list .cxItem .cxItemView > div:not(.check):not(.action):not(.avatar) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  min-height: 30px;
}

.custom-app-button {
  display: flex !important;
  align-items: center;
}

.custom-app-button .icon {
  font-size: 13px;
}

body[data-theme="light"] .custom-app-button.invert .icon {
  -webkit-filter: invert(1);
  filter: invert(1);
}

.custom-app-button .icon img {
  margin: 0 0.5rem 0 -0.15rem;
  height: 15px;
}

.custom-app-button .text {
  font-size: 13px;
}

body[data-theme="dark"] .file-icon__binary {
  -webkit-filter: invert(1);
  filter: invert(1);
}

@media (max-width: 991.98px) {
  .log-list .heading {
    position: static;
  }

  .search-btn {
    left: 10px;
    top: 10px;
  }
}

.app-version_mr-15 {
  margin: 0px 15px;
}

.beta-container {
  position: relative;
}

.beta {
  position: absolute;
  top: -2px;
  right: 2px;
  color: #fff;
  background-color: red;
  padding: 2px;
  border-radius: 3px;
  font-size: 9px;
  font-weight: bold;
  text-transform: uppercase;
}

.notification-img {
  max-width: 100%;
}

.mobile-other-dropdown {
  max-height: 240px;
  overflow: auto;
}

.sun {
  margin-top: -3px;
}

.bulk-status {
  border-radius: 4px;
  border: 1px solid #27ae60;
  display: flex;
  padding: 5px 10px;
  margin-top: 15px;
  font-size: 13px;
  min-width: 200px;
  color: #27ae60;
}

.bulk-status:hover {
  color: #000;
}

.bulk-status--yellow {
  border-color: orange;
  color: orange;
}

.bulk-status--red {
  border-color: orangered;
  color: orangered;
}

.bulk-status:hover {
  text-decoration: none;
}

.widgets li div,
.applications .dropdown-content div {
  position: relative;
  overflow: hidden;
  margin: auto;
  width: 52px;
}

.widgets div.beta-ribbon,
.applications div.beta-ribbon {
  border-radius: 3px;
  position: absolute;
  width: 28px;
  height: 16px;
  background-image: linear-gradient(to right, #2466e6, #012f57);
  top: 1px;
  text-align: center;
  font-size: 9px;
  font-family: sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  line-height: 17px;
}

.applications div.beta-ribbon {
  left: 0px;
}

.widgets div.beta-ribbon {
  right: 0px;
}

@media (max-width: 1280px) {
  .bulk-status__title {
    display: none;
  }
}
