.sidebar-main label {
  color: #fff;
}

.mw-mobile-menu label {
  color: var(--gray);
}

.sidebar.minimum .sidebar-main.hide-when-minimum {
  display: none;
}

.react-datepicker-wrapper {
  display: block !important;
}

.clear-input {
  cursor: pointer;
  position: absolute;
  right: 7px;
  bottom: 3px;
  font-size: 15px;
  color: var(--primary-text-color);
  text-shadow: none;
}
.clear-input:hover {
  color: var(--primary-text-color);
}

.sticky-button {
  position: sticky;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 1rem 0;
  background-color: var(--sidebar-bg-color);
}
.mw-mobile-menu .sticky-button {
  background-color: var(--white-space-bg-color);
}
