.availability-icon {
  font-size: initial;
  padding: 0 4px;
  margin-right: 4px;
}

.calendar-availability table {
  white-space: nowrap;
  margin: 0;
  border: none;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  border: unset;
  display: block;
  margin-bottom: 16px;
}
.calendar-availability table td,
.calendar-availability table th {
  border: 1px solid var(--table-border-color);
  padding: 0;
  max-width: 38px;
}
.calendar-availability table thead th {
  padding: 2px;
  position: sticky;
  top: 0;
  z-index: 1;
  background: var(--table2-th-bg-color);
}
.calendar-availability table thead th:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
  text-align: center;
}
.calendar-availability table thead th:first-child::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-left: 1px solid var(--table-border-color);
  border-right: 1px solid var(--table-border-color);
}
.calendar-availability table thead tr.days th {
  text-align: center;
  font-weight: 500;
}
.calendar-availability table thead tr.hours th {
  text-align: center;
  font-weight: normal;
  font-size: smaller;
}
.calendar-availability table thead tr.hours td {
  text-align: center;
}

.calendar-availability table td {
  background: #fff;
  text-align: center;
  position: relative;
}

.calendar-availability table tbody th {
  font-weight: 300;
  text-align: left;
  position: relative;
}
.calendar-availability table tbody th.col-email {
  background: var(--table2-th-bg-color);
}
.calendar-availability table tbody th.self-email {
  background: var(--table2-th-bg-color);
}

.calendar-availability table tbody th {
  position: sticky;
  left: 0;
  background-color: white;
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 2;
  padding: 6px;
  font-size: 12px;
}
.calendar-availability caption {
  text-align: left;
  padding: 0.25rem;
  position: sticky;
  left: 0;
}

[role="region"][aria-labelledby][tabindex] {
  width: 100%;
  max-height: 98vh;
  overflow: auto;
  padding: 0;
}

.chip-indicator > span {
  align-self: center;
  margin-left: 4px;
}

.av-bar {
  position: absolute;
  left: 0;
  width: 13rem;
  top: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(0, 0, 255, 0.05);
}

.av-bar.av-free {
  background-color: #ffffff; /* Zimbra Free */
}
.av-bar.av-non-working {
  background-color: #c9c9c9; /* Zimbra Non-working */
}
.av-bar.av-no_data {
  background-color: #fef5d1; /* Zimbra Unknown */
}
.av-bar.av-busy {
  background-color: #63a5eb; /* Zimbra Busy */
}
.av-bar.av-tentative {
  background-color: #c2dfe2; /* Zimbra Tentative */
}
.av-bar.av-unavailable {
  background-color: #7d5cad; /* Zimbra Non-working */
}
.av-bar.av-event {
  border-left: 3px solid #387e22;
  border-right: 3px solid #80170e;
  background-color: transparent;
}
.availability-legend {
  display: flex;
  justify-content: flex-end;
  text-align: center;
  gap: 32px;
}
.availability-legend div {
  display: flex;
  align-items: center;
  gap: 6px;
}
.availability-legend .av-bar {
  position: relative;
  font-size: small;
  border: 1px solid #00000021;
  width: 16px;
  height: 16px;
}

.attendeesNoOrg {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 6px;
  margin: 0px 6px;
  display: flex;
  flex-wrap: wrap;
}
.attendeesNoOrg div {
  background-color: #7a7a7a7d;
  border-radius: 4px;
  padding: 5px;
  margin-right: 4px;
  font-size: 0.9rem;
}
