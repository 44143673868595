.modal-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  display: block !important;
  overflow: hidden !important;
}

.modal-container {
  outline: 0;
  margin-top: 0;
}
.modal-content {
  width: 100%;
  margin: 25px 0;
  max-height: calc(100vh - 50px);
  word-break: break-word;
  background-color: var(--white-space-bg-color) !important;
}

.modal-content form {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.modal-title {
  display: flex;
  align-items: center;
}
.modal-title i {
  display: block;
  margin-right: 10px;
  color: var(--primary-color);
  background-color: #216ff333;
  font-size: 16px;
  min-width: 34px;
  max-width: 34px;
  text-align: center;
  line-height: 34px;
  border-radius: 50%;
}

.calendar-event-modal .modal-title i {
  background-color: unset;
  font-size: 28px;
}

.modal-body {
  overflow: auto;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
}

.modal-header,
.modal-footer {
  border-color: var(--border-color) !important;
}

@media (max-width: 575.98px) {
  .modal-content {
    margin: 7px 0;
    max-height: calc(100vh - 14px);
  }
}
