.previewer__backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  display: grid;
  place-content: center;
}

.previewer__container {
  margin-top: 96px;
  margin-bottom: 56px;
  overflow: auto;
}

.previewer__no-content {
  border-radius: 2px;
  background-color: var(--white-space-bg-color);
  padding: 15px;
}

.previewer__video {
  max-width: 100%;
  max-height: calc(100vh - 160px);
}

.previewer__pdf {
  position: relative;
  display: flex;
  flex-direction: column;
  color: #fff;
  height: calc(100vh - 96px - 56px);
  width: 90vw;
  margin-left: auto;
  margin-right: auto;
}

.previewer__title {
  font-size: 16px;
  font-weight: bold;
}

.previewer__all-button {
  position: absolute;
  bottom: 10px;
  width: auto;
  width: 100%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}
.previewer__tools {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 5px;
  right: 0;
  z-index: 2;
  padding: 15px;
}

.previewer__item {
  max-width: 100%;
  max-height: calc(100vh - 96px - 56px);
}

.previewer__button {
  border: none !important;
  font-size: 22px !important;
  color: #fff !important;
  cursor: pointer !important;
  z-index: 3 !important;
}

.previewer__button:hover {
  color: #ddd;
}

.previewer__txt {
  color: #000;
  background-color: #fff;
  padding: 20px;
  min-height: 600px;
  width: 90vw;
  margin-left: auto;
  margin-right: auto;
}

.previewer__eyp {
  width: 80vw;
}

.previewer__eyp .modal-content {
  max-height: none;
  margin: 0;
}
