.my-tippy-arrow,
.my-tippy-arrow::before {
  position: absolute;
  width: 16px;
  height: 16px;
  z-index: 9997;
}

.my-tippy-arrow::before {
  content: "";
  transform: rotate(45deg);
  background: var(--white-space-bg-color);
  border-top: 1px solid var(--border-color);
  border-left: 1px solid var(--border-color);
}

.my-tippy-tooltip {
  font-weight: bold;
  padding: 4px 8px;
  font-size: 13px;
  border-radius: 4px;

  box-shadow: -7px 8px 16px 0 var(--shadow-color);
  color: var(--tooltip-text-color);
  text-align: left;
  background-color: var(--white-space-bg-color);
  border: 1px solid var(--border-color);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}

.my-tippy-tooltip[data-placement^="top"] > .my-tippy-arrow {
  bottom: -8px;
}

.my-tippy-tooltip[data-placement^="bottom"] > .my-tippy-arrow {
  top: -8px;
}

.my-tippy-tooltip[data-placement^="left"] > .my-tippy-arrow {
  right: -8px;
}

.my-tippy-tooltip[data-placement^="right"] > .my-tippy-arrow {
  left: -8px;
}

.Popover {
  width: max-content;
  max-width: calc(100vw - 20px);
  background-color: var(--white-space-bg-color);
  border: 1px solid var(--border-color);
  font-size: 90%;
  padding: 4px 8px;
  border-radius: 4px;
  text-align: left;
  z-index: 9999;
}

.Popover-arrow {
  width: 10px;
  height: 10px;
  background-color: var(--white-space-bg-color);
  position: absolute;
  transform: rotate(45deg);
  border: 1px solid var(--border-color);
}

.Popover-arrow-top {
  bottom: -6px;
  left: 50%;
  border-top-width: 0;
  border-left-width: 0;
}

.Popover-arrow-bottom {
  top: -6px;
  left: 50%;
  border-bottom-width: 0;
  border-right-width: 0;
}

.Popover-arrow-left {
  right: -6px;
  top: 50%;
  border-top-width: 0;
  border-left-width: 0;
}

.Popover-arrow-right {
  left: -6px;
  top: 50%;
  border-top-width: 0;
  border-right-width: 0;
}
button {
  border: 0;
  background-color: transparent;
}
