.calendar-event {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 0;
  color: #fff;
  text-align: left;
}

.calendar-popover {
  cursor: auto !important;
  font-weight: normal;
}

.calendar-popover .divider {
  margin-top: 5px;
}

.calendar-container {
  height: calc(100vh - 60px);
  padding: 0 15px 15px 15px;
  background-color: var(--white-space-bg-color);
}

.rbc-agenda-event-cell .calendar-event {
  color: var(--primary-text-color);
}

.rbc-toolbar {
  padding: 10px;
  margin-bottom: 0;
}

.rbc-btn-group button {
  border-color: var(--calendar-toolbar-btn-border-color);
  color: var(--calendar-toolbar-btn-text-color);
  background-color: var(--calendar-toolbar-btn-bg-color);
}

.rbc-btn-group button:hover,
.rbc-btn-group button:active,
.rbc-btn-group button:focus,
.rbc-btn-group button:active:hover,
.rbc-btn-group .rbc-active {
  box-shadow: none !important;
  border-color: var(--calendar-toolbar-btn-border-color) !important;
  color: var(--calendar-toolbar-btn-hover-text-color) !important;
  background-color: var(--calendar-toolbar-btn-hover-bg-color) !important;
}

.rbc-month-view,
.rbc-header,
.rbc-day-bg,
.rbc-month-row,
.rbc-time-content > * + * > *,
.rbc-day-slot .rbc-time-slot,
.rbc-time-view,
.rbc-timeslot-group,
.rbc-time-header.rbc-overflowing,
.rbc-time-header-content,
.rbc-time-content,
.rbc-agenda-view table.rbc-agenda-table,
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td,
.rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
  border-color: var(--border-color) !important;
}

.rbc-off-range-bg {
  background-color: #111;
  background-color: var(--primary-bg-color);
}

.rbc-selected-cell {
  background-color: var(--action-link-hover-bg-color);
}

.rbc-today {
  background-color: var(--calendar-today-bg-color);
}

.rbc-event {
  display: flex !important;
  overflow: hidden !important;
  max-width: 100% !important;
}

.rbc-event-label {
  flex-shrink: 0 !important;
  margin-right: 5px !important;
  white-space: nowrap !important;
}

.rbc-event-content {
  flex-grow: 1 !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  min-width: 0 !important;
  max-width: calc(100% - 70px) !important;
  display: block !important;
}

.rbc-agenda-time-cell {
  text-transform: uppercase !important;
}

.react-datepicker__time-list-item {
  text-transform: uppercase;
}

.calendar-input {
  text-transform: uppercase !important;
}

.tippy-box.calendar {
  background-color: var(--white-space-bg-color) !important;
  color: var(--primary-text-color) !important;
  border: 1px solid var(--border-color) !important;
}

.tippy-box.calendar .tippy-arrow::before {
  color: var(--border-color) !important;
}
