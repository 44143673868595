@keyframes blink {

  0%,
  15%,
  85%,
  100% {
    opacity: 1;
  }

  60% {
    opacity: 0.2;
  }
}

.ph-placeholder {
  animation: 2250ms blink ease-in-out infinite;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.ph-wrap {
  flex-wrap: wrap;
}

.ph-group {
  margin-bottom: 10px;
}

.ph-line {
  display: flex;
  justify-content: space-between;
}

.ph-item {
  background-color: var(--ph-item-bg-color);
  margin-bottom: 5px;
  border-radius: 2px;
}

.ph-dark {
  background-color: var(--ph-dark-item-bg-color);
}

.ph-h-15 {
  height: 15px;
}

.ph-h-20 {
  height: 20px;
}

.ph-h-30 {
  height: 30px;
}

.ph-h-150 {
  height: 150px;
}

.ph-w-5 {
  width: 5%;
}

.ph-w-10 {
  width: 10%;
}

.ph-w-20 {
  width: 20%;
}

.ph-w-60 {
  width: 60%;
}

.ph-w-80 {
  width: 80%;
}

.ph-w-90 {
  width: 90%;
}

.ph-w-100 {
  width: 100%;
}

.input-sm-placeholder::placeholder {
  font-size: 0.99rem;
}