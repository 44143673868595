.splash {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;

  background: #23283b;
}

.splash-logo {
  max-height: 150px;
}
