.pus-name {
  width: auto !important;
}

.btn-info__mini {
  height: 20px;
  margin-left: 5px;
  padding: 0 !important;
  line-height: 0 !important;
}

.info__item--row {
  position: relative;
  font-weight: normal;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  width: 300px;
}

.info__item--row-dropdown {
  position: absolute;
  min-width: 100%;
  left: 0;
  top: 100%;
  background-color: var(--white-space-bg-color);
  border-radius: 4px;
  border: 1px solid var(--border-color);
  box-shadow: -7px 8px 16px 0 rgb(55 70 95 / 20%);
  z-index: 1;
}
.info__item--row-dropdown-head {
  display: flex;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid var(--border-color);
}
.info__item--row-dropdown-foot {
  display: flex;
  justify-content: flex-end;
  padding: 1px 5px;
}

.info-box-container {
  max-width: 380px;
}

.btn-outlined {
  border: 0.5px solid rgb(203, 203, 203) !important;
  border-radius: 3px;
  width: 65px;
}

.btn-outlined:hover {
  color: white !important;
}

.text-caption {
  font-size: 10px !important;
}
