.tags .hide-when-minimum {
  display: none !important;
}

.tags-item {
  height: 46px;
  font-size: 12px;
  padding: 12px 10px;
  color: #c4c4c7;
  cursor: pointer;
}

.tags-item__mobile {
  color: var(--primary-text-color) !important;
}

.tags-item--active,
.tags-item:hover {
  background: var(--sidebar-active-link-bg-color);
  color: #c4c4c7;
  text-decoration: none;
}

.tags-item:hover .tag-items__buttoms {
  display: flex;
}

.tags-item--active {
  background: var(--sidebar-active-link-bg-color);
}

.tags-item-actions-circle {
  border-radius: 50%;
  margin-left: 3px;
  width: 22px;
  height: 22px;
}

.tag-items__buttoms {
  display: none;
}

.tags-more .action-btn {
  padding: 0 20px;
  margin-right: -10px;
  border: 0;
  background: transparent;
  color: #ffffff;
  outline: none !important;
}

.tags-container__isMobile {
  /* height: calc(100vh - 305px) !important; */
  box-sizing: border-box;
}

.tags-container__isScreen {
  /* height: calc(100vh - 193px) !important; */
  box-sizing: border-box;
}