.boundary {
  position: relative;
  padding: 40px 0;
  text-align: center;
}
.boundary img {
  height: 150px;
  margin: 0 auto;
}
.boundary .name {
  font-size: 20px;
  font-weight: 600;
}
.boundary .desc1 {
  font-size: 15px;
}
.boundary .desc2 {
  font-size: 15px;
  margin-bottom: 20px;
}
