.pus-qr__container {
  position: relative;
}

.pus-qr__spinner,
.pus-qr__disabled {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
}

.pus-qr__disabled {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.8);
}

.login__selectable {
  max-width: 50%;
}

.login__selectable section {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
